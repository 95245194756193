/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An opaque identifier to be used to access individual instance of objects */
  UID: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-01-01 13:00:00`. */
  DateTime: any;
  /** A Json string. Will be validated. */
  Json: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /**
   * A field whose value conforms to the standard internet email address format as
   * specified in RFC822: https://www.w3.org/Protocols/rfc822/.
   */
  EmailAddress: any;
  /** An email address. Will be validated. */
  Email: any;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
};


export type Answer = {
  __typename?: 'Answer';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  value: Scalars['JSON'];
  statvalue?: Maybe<Scalars['Float']>;
  question_id: Scalars['UID'];
  cro_id: Scalars['UID'];
  site_id: Scalars['UID'];
  patient_id: Scalars['UID'];
  section_question_id: Scalars['UID'];
  form_id: Scalars['UID'];
  event_id?: Maybe<Scalars['UID']>;
  index?: Maybe<Scalars['Int']>;
  patient: Patient;
  site: Site;
  event: Event;
  form: Form;
  query?: Maybe<AnswerQuery>;
};

export type AnswerQuery = {
  __typename?: 'AnswerQuery';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  status: QueryStatusType;
  section_question_id: Scalars['UID'];
  operator_id: Scalars['UID'];
  answer_id: Scalars['UID'];
  event_id?: Maybe<Scalars['UID']>;
  comments: Array<QueryComment>;
  operator: Operator;
  answer: Answer;
  event: Event;
};

export type BooleanFieldFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type Check = {
  __typename?: 'Check';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  categories?: Maybe<Array<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
  strategies: Array<CheckStrategyShape>;
};

export enum CheckStrategy {
  FormsStrategy = 'FormsStrategy',
  DaysStrategy = 'DaysStrategy'
}

export type CheckStrategyShape = {
  __typename?: 'CheckStrategyShape';
  strategy: CheckStrategy;
  strategy_arguments: Array<Scalars['String']>;
};

export type Configuration = {
  __typename?: 'Configuration';
  fieldname: ConfigurationName;
  fieldvalue?: Maybe<Scalars['JSON']>;
  fieldtype: Scalars['String'];
  updated_at: Scalars['DateTime'];
  created_at: Scalars['DateTime'];
};

export enum ConfigurationName {
  ArmsNumber = 'arms_number',
  PatientTarget = 'patient_target',
  CompetitiveEnrollment = 'competitive_enrollment',
  ScreeningMaxTime = 'screening_max_time',
  Sponsor = 'sponsor',
  StudyType = 'study_type',
  StudyObject = 'study_object',
  TherapeuticArea = 'therapeutic_area',
  Segmentation = 'segmentation',
  SegmentationNumber = 'segmentation_number',
  CycleDuration = 'cycle_duration',
  StudyPeriod = 'study_period',
  LastPatientVisit = 'last_patient_visit',
  EnrollmentSteps = 'enrollment_steps',
  DropoutReasons = 'dropout_reasons',
  TrialStatus = 'trial_status',
  TrialStartedAt = 'trial_started_at',
  EndOfTreatmentSchedule = 'end_of_treatment_schedule'
}

export type ConfigurationStatus = {
  __typename?: 'ConfigurationStatus';
  status: TrialStatus;
  total: Scalars['Int'];
  current: Scalars['Int'];
  completed: Scalars['Boolean'];
  hint: Array<ConfigurationStatusHint>;
};

export enum ConfigurationStatusHint {
  Form = 'FORM',
  Cro = 'CRO',
  Settings = 'SETTINGS',
  Protocol = 'PROTOCOL',
  Schedules = 'SCHEDULES'
}

export type CreateCheckStrategyInput = {
  strategy: CheckStrategy;
  strategy_arguments: Array<Scalars['String']>;
};

export type CreateOperatorInput = {
  email: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type CreatePageInput = {
  title: Scalars['String'];
  columns?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

export type CreateQuestionInput = {
  code: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  valuetype: ValueType;
  rendertype: Scalars['String'];
  sharable?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  definitions?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
};

export type CreateSectionInput = {
  depends_on?: Maybe<Scalars['UID']>;
  depends_definition?: Maybe<Scalars['JSON']>;
  title?: Maybe<Scalars['String']>;
  columns?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  repeatable?: Maybe<Scalars['Boolean']>;
};

export type Cro = {
  __typename?: 'Cro';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  patients_enrolled: Scalars['Int'];
  patient_target: Scalars['Int'];
  sites: Array<Site>;
  directors: Array<Operator>;
  operators: Array<Operator>;
};

export type Database = {
  __typename?: 'Database';
  id: Scalars['ID'];
  name: Scalars['String'];
  password: Scalars['String'];
  jwt_secret: Scalars['String'];
  status: DatabaseStatus;
};

/** A paginated list of Database items. */
export type DatabasePaginator = {
  __typename?: 'DatabasePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Database items. */
  data: Array<Database>;
};

/** Stato del database relativo al trial */
export enum DatabaseStatus {
  /** Appena generato via createTrial */
  New = 'new',
  /** Creato sul db server */
  Created = 'created',
  /** Inizializzato (eseguite migrations) */
  Inited = 'inited',
  /** Avviata istanza node relativo */
  Started = 'started',
  /** Terminato e non avviabile */
  Archived = 'archived'
}


export type DateFieldFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  ne?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  nin?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  is_null?: Maybe<Scalars['Boolean']>;
};


export type Diary = {
  __typename?: 'Diary';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  form_id: Scalars['UID'];
  meta?: Maybe<Scalars['JSON']>;
  repeat_for: Scalars['Int'];
  form: Form;
};



export type EnrollmentStep = {
  __typename?: 'EnrollmentStep';
  step: Scalars['Int'];
  schedule_id?: Maybe<Scalars['UID']>;
  set_status: PatientStatus;
  group: Scalars['Int'];
  disables: Array<Scalars['Int']>;
  allowed: Scalars['Boolean'];
  active: Scalars['Boolean'];
  schedule?: Maybe<Schedule>;
  event?: Maybe<Event>;
};


export type EnrollmentStepEventArgs = {
  patient_id?: Maybe<Scalars['UID']>;
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  schedule_id?: Maybe<Scalars['UID']>;
  questionnaire_id?: Maybe<Scalars['UID']>;
  diary_id?: Maybe<Scalars['UID']>;
  patient_id: Scalars['UID'];
  parent_id?: Maybe<Scalars['UID']>;
  status: EventStatusType;
  completed_at?: Maybe<Scalars['DateTime']>;
  persisted: Scalars['Boolean'];
  child_events: Array<Event>;
  parent?: Maybe<Event>;
  schedule?: Maybe<Schedule>;
  questionnaire?: Maybe<Form>;
  diary?: Maybe<Diary>;
  patient: Patient;
  form_ids: Array<Scalars['UID']>;
  completed_pages: Scalars['JSON'];
};


export type EventChild_EventsArgs = {
  status?: Maybe<Array<EventStatusType>>;
};

export type EventsPaginated = {
  __typename?: 'EventsPaginated';
  items: Array<Event>;
  paginationInfo: PaginationInfo;
};

export type EventStatusFieldFilterInput = {
  eq?: Maybe<EventStatusType>;
  ne?: Maybe<EventStatusType>;
  in?: Maybe<Array<EventStatusType>>;
  nin?: Maybe<Array<EventStatusType>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export enum EventStatusType {
  Todo = 'TODO',
  Warning = 'WARNING',
  Done = 'DONE',
  Validated = 'VALIDATED'
}

export type FindAnswerFilterInput = {
  id?: Maybe<UidFieldFilterInput>;
  event_id?: Maybe<UidFieldFilterInput>;
  question_id?: Maybe<UidFieldFilterInput>;
  cro_id?: Maybe<UidFieldFilterInput>;
  site_id?: Maybe<UidFieldFilterInput>;
  patient_id?: Maybe<UidFieldFilterInput>;
  section_question_id?: Maybe<UidFieldFilterInput>;
  index?: Maybe<IntFieldFilterInput>;
  event?: Maybe<FindEventFilterInput>;
};

export type FindChecksFiltersInput = {
  _or?: Maybe<Array<FindChecksFiltersInput>>;
  id?: Maybe<UidFieldFilterInput>;
  title?: Maybe<StringFieldFilterInput>;
  categories?: Maybe<StringArrayFieldFilterInput>;
  created_at?: Maybe<DateFieldFilterInput>;
};

export type FindDiariesFilterInput = {
  _or?: Maybe<Array<FindDiariesFilterInput>>;
  id?: Maybe<UidFieldFilterInput>;
  title?: Maybe<StringFieldFilterInput>;
  form_id?: Maybe<UidFieldFilterInput>;
  form?: Maybe<FindFormFilterInput>;
};

export type FindEventFilterInput = {
  _or?: Maybe<Array<FindEventFilterInput>>;
  id?: Maybe<UidFieldFilterInput>;
  patient_id?: Maybe<UidFieldFilterInput>;
  schedule_id?: Maybe<UidFieldFilterInput>;
  questionnaire_id?: Maybe<UidFieldFilterInput>;
  parent_id?: Maybe<UidFieldFilterInput>;
  created_at?: Maybe<DateFieldFilterInput>;
  status?: Maybe<EventStatusFieldFilterInput>;
  schedule?: Maybe<FindSchedulesFiltersInput>;
  patient?: Maybe<FindPatientsFiltersInput>;
};

export type FindFormFilterInput = {
  _or?: Maybe<Array<FindFormFilterInput>>;
  id?: Maybe<UidFieldFilterInput>;
  title?: Maybe<StringFieldFilterInput>;
  draft?: Maybe<BooleanFieldFilterInput>;
  created_at?: Maybe<DateFieldFilterInput>;
  categories?: Maybe<StringArrayFieldFilterInput>;
  type?: Maybe<FormTypeFieldFilterInput>;
};

export type FindPatientsFiltersInput = {
  _or?: Maybe<Array<FindPatientsFiltersInput>>;
  code?: Maybe<StringFieldFilterInput>;
  gender?: Maybe<StringFieldFilterInput>;
  yob?: Maybe<IntFieldFilterInput>;
  id?: Maybe<UidFieldFilterInput>;
  cro_id?: Maybe<UidFieldFilterInput>;
  site_id?: Maybe<UidFieldFilterInput>;
  operator_id?: Maybe<UidFieldFilterInput>;
  started_at?: Maybe<DateFieldFilterInput>;
  created_at?: Maybe<DateFieldFilterInput>;
  updated_at?: Maybe<DateFieldFilterInput>;
  status?: Maybe<PatientStatusFieldFilterInput>;
  dropped_at?: Maybe<DateFieldFilterInput>;
  dropped_reason?: Maybe<StringFieldFilterInput>;
};

export type FindQueriesFiltersInput = {
  _or?: Maybe<Array<FindQueriesFiltersInput>>;
  id?: Maybe<UidFieldFilterInput>;
  status?: Maybe<QueryStatusTypeFieldFilterInput>;
  section_question_id?: Maybe<UidFieldFilterInput>;
  answer_id?: Maybe<UidFieldFilterInput>;
  operator_id?: Maybe<UidFieldFilterInput>;
  event_id?: Maybe<UidFieldFilterInput>;
  created_at?: Maybe<DateFieldFilterInput>;
  updated_at?: Maybe<DateFieldFilterInput>;
  answer?: Maybe<FindAnswerFilterInput>;
  event?: Maybe<FindEventFilterInput>;
};

export type FindSchedulesFiltersInput = {
  _or?: Maybe<Array<FindSchedulesFiltersInput>>;
  id?: Maybe<UidFieldFilterInput>;
  scope?: Maybe<StringFieldFilterInput>;
  title?: Maybe<StringFieldFilterInput>;
  required?: Maybe<BooleanFieldFilterInput>;
  offset?: Maybe<IntFieldFilterInput>;
  target_id?: Maybe<UidFieldFilterInput>;
  target_type?: Maybe<StringFieldFilterInput>;
  enable_form_ids?: Maybe<UidArrayFieldFilterInput>;
  created_at?: Maybe<DateFieldFilterInput>;
  updated_at?: Maybe<DateFieldFilterInput>;
  categories?: Maybe<ScheduleCategoriesArrayFieldFilterInput>;
};

export type Form = {
  __typename?: 'Form';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  draft: Scalars['Boolean'];
  categories?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<FormTypeEnum>;
  schedules: Array<Schedule>;
  pagesInfo: Array<PageInfo>;
  page?: Maybe<Page>;
};


export type FormPageArgs = {
  id?: Maybe<Scalars['UID']>;
};

export enum FormTypeEnum {
  Questionnaire = 'QUESTIONNAIRE',
  CommonForm = 'COMMON_FORM'
}

export type FormTypeFieldFilterInput = {
  eq?: Maybe<FormTypeEnum>;
  ne?: Maybe<FormTypeEnum>;
  in?: Maybe<Array<FormTypeEnum>>;
  nin?: Maybe<Array<FormTypeEnum>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

/** Stato del trial cui questa identity appartiene */
export enum IdentityStatus {
  /** Trial in corso di inizializzazione. Non selezionabile */
  Init = 'init',
  /** Trial in attesa di essere configurato */
  New = 'new',
  /** Trial configurato, in attesa di essere avviato */
  Ready = 'ready',
  /** Trial in corso */
  Started = 'started',
  /** Trial terminato */
  Archived = 'archived'
}

export type IntFieldFilterInput = {
  eq?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  nin?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type Invite = {
  __typename?: 'Invite';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  invite_code: Scalars['String'];
  email: Scalars['EmailAddress'];
  operator_id: Scalars['UID'];
  accepted_at?: Maybe<Scalars['DateTime']>;
  operator: Operator;
};



export type MonitorProgress = {
  __typename?: 'MonitorProgress';
  totalPatients: Scalars['Int'];
  totalEvents: Scalars['Int'];
  totalQueries: Scalars['Int'];
  events: Array<MonitorProgressEvent>;
  queries: Array<MonitorProgressQuery>;
};

export type MonitorProgressEvent = {
  __typename?: 'MonitorProgressEvent';
  total: Scalars['Int'];
  type: EventStatusType;
};

export type MonitorProgressQuery = {
  __typename?: 'MonitorProgressQuery';
  total: Scalars['Int'];
  type: QueryStatusType;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accepts an associations to a patient */
  acceptAssociation: PatientIdentity;
  /** Accepts an invite to a trial */
  acceptInvite: TrialIdentity;
  createCheck: Check;
  createCro: Cro;
  createDiary: Diary;
  createEvent: Event;
  createForm: Form;
  createOperator: Operator;
  createPage: Page;
  createPatient: Patient;
  createQuery: AnswerQuery;
  createQueryComment: QueryComment;
  createQuestion: Question;
  createSchedule: Schedule;
  createSection: Section;
  createSectionQuestion: SectionQuestion;
  createSite: Site;
  /** Crea un trial. Accesso:auth0 Role 'manager' o 'superuser' */
  createTrial: TrialIdentity;
  deleteCheck: Scalars['Boolean'];
  deleteDiary: Scalars['Boolean'];
  deleteForm: Scalars['Boolean'];
  deleteOperator: Scalars['Boolean'];
  deletePage: Scalars['Boolean'];
  deleteQuery: Scalars['Boolean'];
  deleteQueryComment: Scalars['Boolean'];
  deleteQuestion: Scalars['Boolean'];
  deleteSchedule: Scalars['Boolean'];
  deleteSection: Scalars['Boolean'];
  deleteSectionQuestion: Scalars['Boolean'];
  freezeTrial: Scalars['Boolean'];
  /** Invita un operatore (medico o amministrativo) a partecipare a un trial */
  inviteOperator: Scalars['String'];
  saveFormAnswers: Array<Answer>;
  sendInvite: Scalars['Boolean'];
  setConfigurationValues: Scalars['Boolean'];
  setEnrollmentStep: EnrollmentStep;
  setEventStatus: Event;
  setPageCompleted: Page;
  startTrial: Scalars['Boolean'];
  storeInvite: Invite;
  updateCheck: Check;
  updateDiary: Diary;
  updateForm: Form;
  updatePage: Page;
  updatePatient: Patient;
  updateQuery: AnswerQuery;
  updateQueryComment: QueryComment;
  updateQuestion: Question;
  updateSchedule: Schedule;
  updateSection: Section;
  updateSectionQuestion: SectionQuestion;
};


export type MutationAcceptAssociationArgs = {
  code: Scalars['String'];
};


export type MutationAcceptInviteArgs = {
  code: Scalars['String'];
};


export type MutationCreateCheckArgs = {
  title: Scalars['String'];
  categories?: Maybe<Array<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
  strategies: Array<CreateCheckStrategyInput>;
};


export type MutationCreateCroArgs = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  authToken?: Maybe<Scalars['String']>;
  operator: CreateOperatorInput;
};


export type MutationCreateDiaryArgs = {
  title: Scalars['String'];
  form_id: Scalars['UID'];
  offset: Scalars['Int'];
  repeat_for?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};


export type MutationCreateEventArgs = {
  parent_id?: Maybe<Scalars['UID']>;
  form_id: Scalars['UID'];
  patient_id: Scalars['UID'];
};


export type MutationCreateFormArgs = {
  type?: Maybe<FormTypeEnum>;
  categories?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};


export type MutationCreateOperatorArgs = {
  description?: Maybe<Scalars['String']>;
  role: RoleType;
  cro_id?: Maybe<Scalars['UID']>;
  site_id?: Maybe<Scalars['UID']>;
};


export type MutationCreatePageArgs = {
  data: CreatePageInput;
  form_id: Scalars['UID'];
};


export type MutationCreatePatientArgs = {
  code: Scalars['String'];
  gender: Scalars['String'];
  yob: Scalars['Int'];
};


export type MutationCreateQueryArgs = {
  section_question_id: Scalars['UID'];
  answer_id: Scalars['UID'];
  message: Scalars['String'];
};


export type MutationCreateQueryCommentArgs = {
  query_id: Scalars['UID'];
  message: Scalars['String'];
};


export type MutationCreateQuestionArgs = {
  sectionData?: Maybe<SetSectionQuestionInput>;
  sectionId?: Maybe<Scalars['UID']>;
  data: CreateQuestionInput;
};


export type MutationCreateScheduleArgs = {
  title: Scalars['String'];
  offset: Scalars['Int'];
  scope?: Maybe<Scalars['String']>;
  repeat_for?: Maybe<Scalars['Float']>;
  target_id?: Maybe<Array<Scalars['UID']>>;
  target_type?: Maybe<Scalars['String']>;
  enable_form_ids?: Maybe<Array<Scalars['UID']>>;
  meta?: Maybe<Scalars['JSON']>;
  categories?: Maybe<Array<ScheduleCategories>>;
};


export type MutationCreateSectionArgs = {
  data: CreateSectionInput;
  pageId: Scalars['UID'];
};


export type MutationCreateSectionQuestionArgs = {
  data: SetSectionQuestionInput;
  questionId: Scalars['UID'];
  sectionId: Scalars['UID'];
};


export type MutationCreateSiteArgs = {
  cro_id: Scalars['UID'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  authToken?: Maybe<Scalars['String']>;
  patient_target: Scalars['Int'];
  operator: CreateOperatorInput;
};


export type MutationCreateTrialArgs = {
  description: Scalars['String'];
  unique?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteCheckArgs = {
  id: Scalars['UID'];
};


export type MutationDeleteDiaryArgs = {
  id: Scalars['UID'];
};


export type MutationDeleteFormArgs = {
  id: Scalars['UID'];
};


export type MutationDeleteOperatorArgs = {
  id: Scalars['UID'];
};


export type MutationDeletePageArgs = {
  id: Scalars['UID'];
};


export type MutationDeleteQueryArgs = {
  id: Scalars['UID'];
};


export type MutationDeleteQueryCommentArgs = {
  id: Scalars['UID'];
};


export type MutationDeleteQuestionArgs = {
  id: Scalars['UID'];
};


export type MutationDeleteScheduleArgs = {
  id: Scalars['UID'];
};


export type MutationDeleteSectionArgs = {
  id: Scalars['UID'];
};


export type MutationDeleteSectionQuestionArgs = {
  id: Scalars['UID'];
};


export type MutationFreezeTrialArgs = {
  freeze?: Maybe<Scalars['Boolean']>;
};


export type MutationInviteOperatorArgs = {
  operator_id: Scalars['ID'];
  email: Scalars['Email'];
  role: RoleType;
  token: Scalars['String'];
};


export type MutationSaveFormAnswersArgs = {
  data: Array<SaveAnswerInput>;
  patient_id: Scalars['UID'];
  event_id?: Maybe<Scalars['UID']>;
  form_id: Scalars['UID'];
};


export type MutationSendInviteArgs = {
  operator_id: Scalars['UID'];
};


export type MutationSetConfigurationValuesArgs = {
  items: Array<SetConfigurationInput>;
};


export type MutationSetEnrollmentStepArgs = {
  data: SetEnrollmentStepInput;
  step: Scalars['Int'];
};


export type MutationSetEventStatusArgs = {
  status: EventStatusType;
  id: Scalars['UID'];
};


export type MutationSetPageCompletedArgs = {
  status?: Maybe<Scalars['Boolean']>;
  event_id: Scalars['UID'];
  page_id: Scalars['UID'];
  form_id: Scalars['UID'];
};


export type MutationStoreInviteArgs = {
  operator_id: Scalars['UID'];
  invite_code: Scalars['String'];
  email: Scalars['EmailAddress'];
};


export type MutationUpdateCheckArgs = {
  data: UpdateCheckInput;
  id: Scalars['UID'];
};


export type MutationUpdateDiaryArgs = {
  data: UpdateDiaryInput;
  id: Scalars['UID'];
};


export type MutationUpdateFormArgs = {
  data: UpdateFormInput;
  id: Scalars['UID'];
};


export type MutationUpdatePageArgs = {
  data: UpdatePageInput;
  id: Scalars['UID'];
};


export type MutationUpdatePatientArgs = {
  data: UpdatePatientInput;
  id: Scalars['UID'];
};


export type MutationUpdateQueryArgs = {
  data: UpdateQueryInput;
  id: Scalars['UID'];
};


export type MutationUpdateQueryCommentArgs = {
  data: UpdateQueryCommentInput;
  id: Scalars['UID'];
};


export type MutationUpdateQuestionArgs = {
  data: UpdateQuestionInput;
  id: Scalars['UID'];
};


export type MutationUpdateScheduleArgs = {
  data: UpdateScheduleInput;
  id: Scalars['UID'];
};


export type MutationUpdateSectionArgs = {
  data: CreateSectionInput;
  id: Scalars['UID'];
};


export type MutationUpdateSectionQuestionArgs = {
  sectionId?: Maybe<Scalars['UID']>;
  data?: Maybe<SetSectionQuestionInput>;
  id: Scalars['UID'];
};

export type Operator = {
  __typename?: 'Operator';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  role: RoleType;
  cro_id?: Maybe<Scalars['UID']>;
  site_id?: Maybe<Scalars['UID']>;
  site?: Maybe<Site>;
  cro?: Maybe<Cro>;
};

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  field: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type OrderChecksInput = {
  id?: Maybe<Order>;
  created_at?: Maybe<Order>;
  updated_at?: Maybe<Order>;
  title?: Maybe<Order>;
};

export type OrderDiariesInput = {
  id?: Maybe<Order>;
  created_at?: Maybe<Order>;
  updated_at?: Maybe<Order>;
  form_id?: Maybe<Order>;
};

export type OrderEventsInput = {
  id?: Maybe<Order>;
  created_at?: Maybe<Order>;
  updated_at?: Maybe<Order>;
  completed_at?: Maybe<Order>;
  status?: Maybe<Order>;
};

export type OrderFormInput = {
  id?: Maybe<Order>;
  created_at?: Maybe<Order>;
  updated_at?: Maybe<Order>;
  title?: Maybe<Order>;
};

export type OrderPatientsInput = {
  id?: Maybe<Order>;
  created_at?: Maybe<Order>;
  updated_at?: Maybe<Order>;
  code?: Maybe<Order>;
  gender?: Maybe<Order>;
  yob?: Maybe<Order>;
  started_at?: Maybe<Order>;
  status?: Maybe<Order>;
};

export type OrderQueriesInput = {
  id?: Maybe<Order>;
  created_at?: Maybe<Order>;
  updated_at?: Maybe<Order>;
  operator_id?: Maybe<Order>;
  event_id?: Maybe<Order>;
  status?: Maybe<Order>;
};

export type OrderSchedulesInput = {
  id?: Maybe<Order>;
  created_at?: Maybe<Order>;
  updated_at?: Maybe<Order>;
  title?: Maybe<Order>;
  offset?: Maybe<Order>;
  scope?: Maybe<Order>;
  target_type?: Maybe<Order>;
};

export type Page = {
  __typename?: 'Page';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  form_id: Scalars['UID'];
  order: Scalars['Int'];
  columns: Scalars['Int'];
  sections: Array<Section>;
};

/** Pagination information about the corresponding list of items. */
export type PageInfo = {
  __typename?: 'PageInfo';
  columns: Scalars['Int'];
  /** Count of nodes in current request. */
  count?: Maybe<Scalars['Int']>;
  created_at: Scalars['DateTime'];
  /** Current page of request. */
  currentPage?: Maybe<Scalars['Int']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  form_id: Scalars['UID'];
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  id: Scalars['UID'];
  /** Last page in connection. */
  lastPage?: Maybe<Scalars['Int']>;
  order: Scalars['Int'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** Total number of node in connection. */
  total?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  hasMore: Scalars['Boolean'];
  total: Scalars['Int'];
};

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Total count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Last page number of the collection. */
  lastPage: Scalars['Int'];
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
  /** Total items available in the collection. */
  total: Scalars['Int'];
};

export type Patient = {
  __typename?: 'Patient';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  status: PatientStatus;
  cro_id: Scalars['UID'];
  site_id: Scalars['UID'];
  operator_id: Scalars['UID'];
  gender: Scalars['String'];
  yob: Scalars['Int'];
  started_at?: Maybe<Scalars['DateTime']>;
  dropped_at?: Maybe<Scalars['DateTime']>;
  dropped_reason?: Maybe<Scalars['String']>;
  operator?: Maybe<Operator>;
  site?: Maybe<Site>;
  cro?: Maybe<Cro>;
  events: Array<Event>;
  questionnaires: Array<Event>;
  common_forms: Array<Event>;
  diaries: Array<Event>;
  enrollment: Array<Event>;
  progress: Array<PatientProgress>;
};


export type PatientEventsArgs = {
  date_end?: Maybe<Scalars['DateTime']>;
  date_start?: Maybe<Scalars['DateTime']>;
};


export type PatientQuestionnairesArgs = {
  status?: Maybe<Array<EventStatusType>>;
};


export type PatientCommon_FormsArgs = {
  status?: Maybe<Array<EventStatusType>>;
};


export type PatientDiariesArgs = {
  status?: Maybe<Array<EventStatusType>>;
};


export type PatientProgressArgs = {
  categories: Array<ScheduleCategories>;
};

export type PatientIdentity = {
  __typename?: 'PatientIdentity';
  id: Scalars['UID'];
  description: Scalars['String'];
  status: IdentityStatus;
  info?: Maybe<Scalars['Json']>;
  role: Scalars['String'];
  scopes: Scalars['String'];
};

export type PatientProgress = {
  __typename?: 'PatientProgress';
  category: ScheduleCategories;
  total: Scalars['Int'];
  completed: Scalars['Int'];
};

export type PatientsPaginated = {
  __typename?: 'PatientsPaginated';
  items: Array<Patient>;
  paginationInfo: PaginationInfo;
};

export enum PatientStatus {
  Acquired = 'ACQUIRED',
  Screened = 'SCREENED',
  Eligible = 'ELIGIBLE',
  Dropped = 'DROPPED',
  Approved = 'APPROVED'
}

export type PatientStatusFieldFilterInput = {
  eq?: Maybe<PatientStatus>;
  ne?: Maybe<PatientStatus>;
  in?: Maybe<Array<PatientStatus>>;
  nin?: Maybe<Array<PatientStatus>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type QueriesPaginated = {
  __typename?: 'QueriesPaginated';
  items: Array<AnswerQuery>;
  paginationInfo: PaginationInfo;
};

/** Database queries */
export type Query = {
  __typename?: 'Query';
  configurationStatus: ConfigurationStatus;
  currentOperator: Operator;
  /** Valida il token passato o quello negli header */
  decodeToken?: Maybe<Scalars['Json']>;
  /** Richiede un token di accesso a un trial o a un paziente. Accesso: auth0 */
  exchangeToken: Scalars['String'];
  /** Richiede la lista dei pazienti che l'utente puo' gestire. Accesso: auth0 */
  getAvailablePatients: Array<PatientIdentity>;
  /** Richiede la lista dei trial cui l'utente ha accesso. Accesso: auth0 */
  getAvailableTrials: Array<TrialIdentity>;
  getCheck?: Maybe<Check>;
  getChecks: Array<Check>;
  getConfigurationValues: Array<Configuration>;
  getCro?: Maybe<Cro>;
  getCros: Array<Cro>;
  /** Elenco dei database disponibili */
  getDatabases?: Maybe<DatabasePaginator>;
  getDiaries: Array<Diary>;
  getDiary?: Maybe<Diary>;
  getEnrollmentSteps: Array<EnrollmentStep>;
  getEvent?: Maybe<Event>;
  getEvents: EventsPaginated;
  getForm?: Maybe<Form>;
  getForms: Array<Form>;
  getInviteList: Array<Invite>;
  getMonitorProgress: MonitorProgress;
  getPatient?: Maybe<Patient>;
  getPatients: PatientsPaginated;
  getQueries: QueriesPaginated;
  getQuery?: Maybe<AnswerQuery>;
  getQuestion?: Maybe<Question>;
  getQuestions: Array<Question>;
  getSchedule?: Maybe<Schedule>;
  getSchedules: Array<Schedule>;
  getSite?: Maybe<Site>;
  trialCred?: Maybe<TrialCred>;
};


/** Database queries */
export type QueryDecodeTokenArgs = {
  token?: Maybe<Scalars['String']>;
  access_token?: Maybe<Scalars['String']>;
};


/** Database queries */
export type QueryExchangeTokenArgs = {
  id: Scalars['UID'];
};


/** Database queries */
export type QueryGetCheckArgs = {
  id: Scalars['UID'];
};


/** Database queries */
export type QueryGetChecksArgs = {
  order?: Maybe<OrderChecksInput>;
  filter?: Maybe<FindChecksFiltersInput>;
};


/** Database queries */
export type QueryGetConfigurationValuesArgs = {
  fieldnames?: Maybe<Array<ConfigurationName>>;
};


/** Database queries */
export type QueryGetCroArgs = {
  id: Scalars['UID'];
};


/** Database queries */
export type QueryGetDatabasesArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


/** Database queries */
export type QueryGetDiariesArgs = {
  order?: Maybe<OrderDiariesInput>;
  filter?: Maybe<FindDiariesFilterInput>;
};


/** Database queries */
export type QueryGetDiaryArgs = {
  id: Scalars['UID'];
};


/** Database queries */
export type QueryGetEnrollmentStepsArgs = {
  alsoDisabled?: Maybe<Scalars['Boolean']>;
};


/** Database queries */
export type QueryGetEventArgs = {
  id: Scalars['UID'];
};


/** Database queries */
export type QueryGetEventsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderEventsInput>;
  filter?: Maybe<FindEventFilterInput>;
};


/** Database queries */
export type QueryGetFormArgs = {
  id: Scalars['UID'];
};


/** Database queries */
export type QueryGetFormsArgs = {
  order?: Maybe<OrderFormInput>;
  filter?: Maybe<FindFormFilterInput>;
};


/** Database queries */
export type QueryGetPatientArgs = {
  id: Scalars['UID'];
};


/** Database queries */
export type QueryGetPatientsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderPatientsInput>;
  filter?: Maybe<FindPatientsFiltersInput>;
};


/** Database queries */
export type QueryGetQueriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderQueriesInput>;
  filter?: Maybe<FindQueriesFiltersInput>;
};


/** Database queries */
export type QueryGetQueryArgs = {
  id: Scalars['UID'];
};


/** Database queries */
export type QueryGetQuestionArgs = {
  id: Scalars['UID'];
};


/** Database queries */
export type QueryGetQuestionsArgs = {
  sharable?: Maybe<Scalars['Boolean']>;
};


/** Database queries */
export type QueryGetScheduleArgs = {
  id: Scalars['UID'];
};


/** Database queries */
export type QueryGetSchedulesArgs = {
  order?: Maybe<OrderSchedulesInput>;
  filter?: Maybe<FindSchedulesFiltersInput>;
};


/** Database queries */
export type QueryGetSiteArgs = {
  id: Scalars['UID'];
};

export type QueryComment = {
  __typename?: 'QueryComment';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  query_id: Scalars['UID'];
  operator_id: Scalars['UID'];
  message: Scalars['String'];
  operator: Operator;
};

export enum QueryStatusType {
  Open = 'OPEN',
  Resolved = 'RESOLVED',
  Canceled = 'CANCELED'
}

export type QueryStatusTypeFieldFilterInput = {
  eq?: Maybe<QueryStatusType>;
  ne?: Maybe<QueryStatusType>;
  in?: Maybe<Array<QueryStatusType>>;
  nin?: Maybe<Array<QueryStatusType>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type Question = {
  __typename?: 'Question';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  sharable: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  valuetype: ValueType;
  rendertype: Scalars['String'];
  required: Scalars['Boolean'];
  definitions: Scalars['JSON'];
};

/** User roles */
export enum RoleType {
  Cd = 'CD',
  Cr = 'CR',
  De = 'DE',
  Dm = 'DM',
  Pd = 'PD',
  Pi = 'PI',
  Sc = 'SC',
  Si = 'SI',
  St = 'ST'
}

export type SaveAnswerInput = {
  answer_id?: Maybe<Scalars['UID']>;
  question_id: Scalars['UID'];
  section_question_id: Scalars['UID'];
  value: Scalars['JSON'];
  index?: Maybe<Scalars['Int']>;
};

export type Schedulable = Form;

export type Schedule = {
  __typename?: 'Schedule';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  scope?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  meta?: Maybe<Scalars['JSON']>;
  categories: Array<ScheduleCategories>;
  target_type?: Maybe<Scalars['String']>;
  target_id: Array<Scalars['UID']>;
  enable_form_ids: Array<Scalars['UID']>;
  schedulable: Array<Schedulable>;
  enable_forms: Array<Form>;
  repeat_for: Scalars['Int'];
};

export enum ScheduleCategories {
  Visit = 'VISIT',
  Qol = 'QOL'
}

export type ScheduleCategoriesArrayFieldFilterInput = {
  eq?: Maybe<Array<ScheduleCategories>>;
  ne?: Maybe<Array<ScheduleCategories>>;
  contains?: Maybe<Array<ScheduleCategories>>;
  overlap?: Maybe<Array<ScheduleCategories>>;
  not_contains?: Maybe<Array<ScheduleCategories>>;
  not_overlap?: Maybe<Array<ScheduleCategories>>;
};

export type Section = {
  __typename?: 'Section';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  depends_on?: Maybe<Scalars['UID']>;
  depends_definition?: Maybe<Scalars['JSON']>;
  title?: Maybe<Scalars['String']>;
  columns: Scalars['Int'];
  repeatable: Scalars['Boolean'];
  order: Scalars['Int'];
  page_id: Scalars['UID'];
  questions: Array<SectionQuestion>;
};

export type SectionQuestion = {
  __typename?: 'SectionQuestion';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  depends_on?: Maybe<Scalars['UID']>;
  depends_definition?: Maybe<Scalars['JSON']>;
  section_id: Scalars['UID'];
  question_id: Scalars['UID'];
  order: Scalars['Int'];
  rendertype?: Maybe<Scalars['String']>;
  question: Question;
  answers: Array<Answer>;
};


export type SectionQuestionAnswersArgs = {
  event_id?: Maybe<Scalars['UID']>;
  patient_id?: Maybe<Scalars['UID']>;
};

export type SetConfigurationInput = {
  fieldname: ConfigurationName;
  fieldvalue: Scalars['JSON'];
  fieldtype: Scalars['String'];
};

export type SetEnrollmentStepInput = {
  set_status?: Maybe<PatientStatus>;
  group?: Maybe<Scalars['Int']>;
  disables?: Maybe<Array<Scalars['Int']>>;
  active?: Maybe<Scalars['Boolean']>;
};

export type SetSectionQuestionInput = {
  depends_on?: Maybe<Scalars['UID']>;
  depends_definition?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  rendertype?: Maybe<Scalars['String']>;
};

export type Site = {
  __typename?: 'Site';
  id: Scalars['UID'];
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  patient_target: Scalars['Int'];
  cro_id: Scalars['UID'];
  patients_enrolled: Scalars['Int'];
  progress: VisitProgress;
  primaryInvestigators: Array<Operator>;
  operators: Array<Operator>;
};

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type StringArrayFieldFilterInput = {
  eq?: Maybe<Array<Scalars['String']>>;
  ne?: Maybe<Array<Scalars['String']>>;
  contains?: Maybe<Array<Scalars['String']>>;
  overlap?: Maybe<Array<Scalars['String']>>;
  not_contains?: Maybe<Array<Scalars['String']>>;
  not_overlap?: Maybe<Array<Scalars['String']>>;
};

export type StringFieldFilterInput = {
  eq?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  nin?: Maybe<Array<Scalars['String']>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type TrialCred = {
  __typename?: 'TrialCred';
  hash?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  user?: Maybe<TrialUser>;
};

export type TrialIdentity = {
  __typename?: 'TrialIdentity';
  id: Scalars['UID'];
  description: Scalars['String'];
  status: IdentityStatus;
  info?: Maybe<Scalars['Json']>;
  role: Scalars['String'];
  scopes: Scalars['String'];
};

export enum TrialStatus {
  Start = 'START',
  Freeze = 'FREEZE',
  Config = 'CONFIG'
}

export type TrialUser = {
  __typename?: 'TrialUser';
  iss: Scalars['String'];
  iat: Scalars['Int'];
  exp: Scalars['Int'];
  sub: Scalars['String'];
  role: RoleType;
  scopes: Array<Scalars['String']>;
  status: Scalars['String'];
  hash: Scalars['String'];
};


export type UidArrayFieldFilterInput = {
  eq?: Maybe<Array<Scalars['UID']>>;
  ne?: Maybe<Array<Scalars['UID']>>;
  contains?: Maybe<Array<Scalars['UID']>>;
  overlap?: Maybe<Array<Scalars['UID']>>;
  not_contains?: Maybe<Array<Scalars['UID']>>;
  not_overlap?: Maybe<Array<Scalars['UID']>>;
};

export type UidFieldFilterInput = {
  eq?: Maybe<Scalars['UID']>;
  ne?: Maybe<Scalars['UID']>;
  in?: Maybe<Array<Scalars['UID']>>;
  nin?: Maybe<Array<Scalars['UID']>>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type UpdateCheckInput = {
  title?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
  strategies?: Maybe<Array<CreateCheckStrategyInput>>;
};

export type UpdateDiaryInput = {
  title?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['UID']>;
  offset?: Maybe<Scalars['Int']>;
  repeat_for?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type UpdateFormInput = {
  title?: Maybe<Scalars['String']>;
  draft?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<FormTypeEnum>;
};

export type UpdatePageInput = {
  title?: Maybe<Scalars['String']>;
  columns?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

export type UpdatePatientInput = {
  gender?: Maybe<Scalars['String']>;
  yob?: Maybe<Scalars['Int']>;
  status?: Maybe<PatientStatus>;
  started_at?: Maybe<Scalars['DateTime']>;
  dropped_at?: Maybe<Scalars['DateTime']>;
  dropped_reason?: Maybe<Scalars['String']>;
};

export type UpdateQueryCommentInput = {
  message: Scalars['String'];
};

export type UpdateQueryInput = {
  status?: Maybe<QueryStatusType>;
};

export type UpdateQuestionInput = {
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  valuetype?: Maybe<ValueType>;
  rendertype?: Maybe<Scalars['String']>;
  sharable?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  definitions?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
};

export type UpdateScheduleInput = {
  title?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  scope?: Maybe<Scalars['String']>;
  repeat_for?: Maybe<Scalars['Float']>;
  target_id?: Maybe<Array<Scalars['UID']>>;
  target_type?: Maybe<Scalars['String']>;
  enable_form_ids?: Maybe<Array<Scalars['UID']>>;
  meta?: Maybe<Scalars['JSON']>;
  categories?: Maybe<Array<ScheduleCategories>>;
};

export enum ValueType {
  String = 'STRING',
  Number = 'NUMBER',
  Array = 'ARRAY',
  Boolean = 'BOOLEAN',
  Datetime = 'DATETIME'
}

export type VisitProgress = {
  __typename?: 'VisitProgress';
  total: Scalars['Int'];
  completed: Scalars['Int'];
};

export type PageDetailsFragment = (
  { __typename?: 'Page' }
  & Pick<Page, 'id' | 'title'>
  & { sections: Array<(
    { __typename?: 'Section' }
    & Pick<Section, 'id' | 'order' | 'columns' | 'page_id' | 'repeatable' | 'title' | 'depends_definition' | 'depends_on'>
    & { questions: Array<(
      { __typename?: 'SectionQuestion' }
      & Pick<SectionQuestion, 'id' | 'order' | 'rendertype' | 'section_id' | 'depends_on' | 'depends_definition'>
      & { answers: Array<(
        { __typename?: 'Answer' }
        & Pick<Answer, 'id' | 'section_question_id' | 'value' | 'updated_at'>
        & { query?: Maybe<(
          { __typename?: 'AnswerQuery' }
          & QueryDetailsFragment
        )> }
      )>, question: (
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'code' | 'label' | 'definitions' | 'rendertype' | 'required' | 'sharable' | 'valuetype'>
      ) }
    )> }
  )> }
);

export type FormDetailsFragment = (
  { __typename?: 'Form' }
  & Pick<Form, 'id' | 'title' | 'created_at' | 'type' | 'draft'>
  & { pagesInfo: Array<(
    { __typename?: 'PageInfo' }
    & Pick<PageInfo, 'id' | 'title'>
  )> }
);

export type OperatorDetailsFragment = (
  { __typename?: 'Operator' }
  & Pick<Operator, 'id' | 'description' | 'role' | 'created_at'>
);

export type CroDetailsFragment = (
  { __typename?: 'Cro' }
  & Pick<Cro, 'id' | 'description' | 'code' | 'created_at' | 'patient_target' | 'patients_enrolled'>
  & { sites: Array<(
    { __typename?: 'Site' }
    & SiteDetailsFragment
  )>, operators: Array<(
    { __typename?: 'Operator' }
    & OperatorDetailsFragment
  )> }
);

export type SiteDetailsFragment = (
  { __typename?: 'Site' }
  & Pick<Site, 'id' | 'description' | 'code' | 'created_at' | 'cro_id' | 'patient_target' | 'patients_enrolled'>
  & { progress: (
    { __typename?: 'VisitProgress' }
    & Pick<VisitProgress, 'completed' | 'total'>
  ), operators: Array<(
    { __typename?: 'Operator' }
    & OperatorDetailsFragment
  )> }
);

export type SchedulePreviewFragment = (
  { __typename?: 'Schedule' }
  & Pick<Schedule, 'id' | 'title' | 'target_id' | 'enable_form_ids'>
  & { enable_forms: Array<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'title'>
  )> }
);

export type ScheduleDetailsFragment = (
  { __typename?: 'Schedule' }
  & Pick<Schedule, 'id' | 'updated_at' | 'offset' | 'target_id' | 'target_type' | 'repeat_for' | 'enable_form_ids' | 'required' | 'scope' | 'meta' | 'title'>
);

export type PatientDetailsFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'code' | 'yob' | 'status' | 'dropped_at' | 'site_id' | 'gender' | 'started_at' | 'created_at' | 'updated_at'>
  & { site?: Maybe<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'code'>
  )> }
);

export type EventPreviewFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'created_at' | 'completed_at' | 'status' | 'id' | 'persisted' | 'form_ids' | 'updated_at'>
  & { child_events: Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'form_ids'>
  )> }
);

export type EventDetailsFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'created_at' | 'completed_at' | 'completed_pages' | 'status' | 'persisted' | 'id' | 'updated_at'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'code'>
    & { site?: Maybe<(
      { __typename?: 'Site' }
      & Pick<Site, 'id' | 'code'>
    )> }
  ), questionnaire?: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'title'>
  )>, diary?: Maybe<(
    { __typename?: 'Diary' }
    & Pick<Diary, 'title'>
  )>, schedule?: Maybe<(
    { __typename?: 'Schedule' }
    & ScheduleDetailsFragment
  )> }
);

export type QueryDetailsFragment = (
  { __typename?: 'AnswerQuery' }
  & Pick<AnswerQuery, 'id' | 'section_question_id' | 'answer_id' | 'event_id' | 'status' | 'updated_at'>
  & { event: (
    { __typename?: 'Event' }
    & { patient: (
      { __typename?: 'Patient' }
      & { site?: Maybe<(
        { __typename?: 'Site' }
        & Pick<Site, 'code'>
      )> }
    ) }
  ), comments: Array<(
    { __typename?: 'QueryComment' }
    & Pick<QueryComment, 'id' | 'message' | 'updated_at' | 'operator_id'>
  )> }
);

export type DiaryDetailsFragment = (
  { __typename?: 'Diary' }
  & Pick<Diary, 'id' | 'updated_at' | 'offset' | 'form_id' | 'repeat_for' | 'meta' | 'title'>
);

export type CheckDetailsFragment = (
  { __typename?: 'Check' }
  & Pick<Check, 'id' | 'categories' | 'meta' | 'title' | 'updated_at'>
  & { strategies: Array<(
    { __typename?: 'CheckStrategyShape' }
    & Pick<CheckStrategyShape, 'strategy' | 'strategy_arguments'>
  )> }
);

export type TrialUserDataFragment = (
  { __typename?: 'TrialUser' }
  & Pick<TrialUser, 'iss' | 'iat' | 'exp' | 'sub' | 'role' | 'scopes' | 'status' | 'hash'>
);

export type GetTrialCredQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTrialCredQuery = (
  { __typename?: 'Query' }
  & { trialCred?: Maybe<(
    { __typename?: 'TrialCred' }
    & Pick<TrialCred, 'hash' | 'token' | 'id'>
    & { user?: Maybe<(
      { __typename?: 'TrialUser' }
      & TrialUserDataFragment
    )> }
  )> }
);

export type CreateControlPlanMutationVariables = Exact<{
  title: Scalars['String'];
  categories?: Maybe<Array<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
  strategies: Array<CreateCheckStrategyInput>;
}>;


export type CreateControlPlanMutation = (
  { __typename?: 'Mutation' }
  & { createCheck: (
    { __typename?: 'Check' }
    & CheckDetailsFragment
  ) }
);

export type UpdateControlPlanMutationVariables = Exact<{
  id: Scalars['UID'];
  data: UpdateCheckInput;
}>;


export type UpdateControlPlanMutation = (
  { __typename?: 'Mutation' }
  & { updateCheck: (
    { __typename?: 'Check' }
    & CheckDetailsFragment
  ) }
);

export type DeleteControlPlanMutationVariables = Exact<{
  id: Scalars['UID'];
}>;


export type DeleteControlPlanMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCheck'>
);

export type CreateCroMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  operator: CreateOperatorInput;
  authToken: Scalars['String'];
}>;


export type CreateCroMutation = (
  { __typename?: 'Mutation' }
  & { createCro: (
    { __typename?: 'Cro' }
    & Pick<Cro, 'id' | 'code' | 'description' | 'created_at' | 'updated_at'>
    & { directors: Array<(
      { __typename?: 'Operator' }
      & OperatorDetailsFragment
    )>, operators: Array<(
      { __typename?: 'Operator' }
      & OperatorDetailsFragment
    )>, sites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'id' | 'code' | 'description'>
    )> }
  ) }
);

export type CompleteStepMutationVariables = Exact<{
  patient_id: Scalars['UID'];
  data: UpdatePatientInput;
  event_id: Scalars['UID'];
  status: EventStatusType;
}>;


export type CompleteStepMutation = (
  { __typename?: 'Mutation' }
  & { updatePatient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'code' | 'gender' | 'yob' | 'status'>
  ), setEventStatus: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'status'>
  ) }
);

export type SetEnrollmentStepMutationVariables = Exact<{
  step: Scalars['Int'];
  data: SetEnrollmentStepInput;
}>;


export type SetEnrollmentStepMutation = (
  { __typename?: 'Mutation' }
  & { setEnrollmentStep: (
    { __typename?: 'EnrollmentStep' }
    & Pick<EnrollmentStep, 'active'>
  ) }
);

export type CreateEventMutationVariables = Exact<{
  patient_id: Scalars['UID'];
  form_id: Scalars['UID'];
  parent_id?: Maybe<Scalars['UID']>;
}>;


export type CreateEventMutation = (
  { __typename?: 'Mutation' }
  & { createEvent: (
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  ) }
);

export type CreateFormMutationVariables = Exact<{
  title: Scalars['String'];
}>;


export type CreateFormMutation = (
  { __typename?: 'Mutation' }
  & { createForm: (
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'title'>
  ) }
);

export type UpdateFormMutationVariables = Exact<{
  id: Scalars['UID'];
  title?: Maybe<Scalars['String']>;
  draft?: Maybe<Scalars['Boolean']>;
  type?: Maybe<FormTypeEnum>;
  categories?: Maybe<Array<Scalars['String']>>;
}>;


export type UpdateFormMutation = (
  { __typename?: 'Mutation' }
  & { updateForm: (
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'title' | 'categories' | 'draft' | 'type'>
  ) }
);

export type CreatePageMutationVariables = Exact<{
  form_id: Scalars['UID'];
  data: CreatePageInput;
}>;


export type CreatePageMutation = (
  { __typename?: 'Mutation' }
  & { createPage: (
    { __typename?: 'Page' }
    & Pick<Page, 'id' | 'title' | 'columns' | 'order'>
    & { sections: Array<(
      { __typename?: 'Section' }
      & Pick<Section, 'id'>
    )> }
  ) }
);

export type UpdatePageMutationVariables = Exact<{
  id: Scalars['UID'];
  data: UpdatePageInput;
}>;


export type UpdatePageMutation = (
  { __typename?: 'Mutation' }
  & { updatePage: (
    { __typename?: 'Page' }
    & Pick<Page, 'id' | 'title' | 'columns' | 'order'>
  ) }
);

export type DeletePageMutationVariables = Exact<{
  id: Scalars['UID'];
}>;


export type DeletePageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePage'>
);

export type CreateSectionMutationVariables = Exact<{
  id: Scalars['UID'];
  data: CreateSectionInput;
}>;


export type CreateSectionMutation = (
  { __typename?: 'Mutation' }
  & { createSection: (
    { __typename?: 'Section' }
    & Pick<Section, 'id' | 'title' | 'columns' | 'order'>
    & { questions: Array<(
      { __typename?: 'SectionQuestion' }
      & Pick<SectionQuestion, 'id'>
    )> }
  ) }
);

export type UpdateSectionMutationVariables = Exact<{
  id: Scalars['UID'];
  data: CreateSectionInput;
}>;


export type UpdateSectionMutation = (
  { __typename?: 'Mutation' }
  & { updateSection: (
    { __typename?: 'Section' }
    & Pick<Section, 'id'>
  ) }
);

export type DeleteSectionMutationVariables = Exact<{
  id: Scalars['UID'];
}>;


export type DeleteSectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSection'>
);

export type CreateQuestionMutationVariables = Exact<{
  data: CreateQuestionInput;
}>;


export type CreateQuestionMutation = (
  { __typename?: 'Mutation' }
  & { createQuestion: (
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'label' | 'rendertype' | 'required' | 'sharable' | 'valuetype' | 'definitions'>
  ) }
);

export type UpdateQuestionMutationVariables = Exact<{
  id: Scalars['UID'];
  data: UpdateQuestionInput;
}>;


export type UpdateQuestionMutation = (
  { __typename?: 'Mutation' }
  & { updateQuestion: (
    { __typename?: 'Question' }
    & Pick<Question, 'id'>
  ) }
);

export type DeleteQuestionMutationVariables = Exact<{
  id: Scalars['UID'];
}>;


export type DeleteQuestionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteQuestion'>
);

export type CreateSectionQuestionMutationVariables = Exact<{
  questionId: Scalars['UID'];
  sectionId: Scalars['UID'];
  data: SetSectionQuestionInput;
}>;


export type CreateSectionQuestionMutation = (
  { __typename?: 'Mutation' }
  & { createSectionQuestion: (
    { __typename?: 'SectionQuestion' }
    & Pick<SectionQuestion, 'id' | 'order' | 'depends_definition' | 'depends_on'>
  ) }
);

export type UpdateSectionQuestionMutationVariables = Exact<{
  id: Scalars['UID'];
  data: SetSectionQuestionInput;
  sectionId?: Maybe<Scalars['UID']>;
}>;


export type UpdateSectionQuestionMutation = (
  { __typename?: 'Mutation' }
  & { updateSectionQuestion: (
    { __typename?: 'SectionQuestion' }
    & Pick<SectionQuestion, 'id'>
  ) }
);

export type SaveFormAnswersMutationVariables = Exact<{
  form_id: Scalars['UID'];
  data: Array<SaveAnswerInput>;
  patient_id: Scalars['UID'];
  event_id: Scalars['UID'];
}>;


export type SaveFormAnswersMutation = (
  { __typename?: 'Mutation' }
  & { saveFormAnswers: Array<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id' | 'value'>
  )> }
);

export type SetPageCompletedMutationVariables = Exact<{
  page_id: Scalars['UID'];
  form_id: Scalars['UID'];
  event_id: Scalars['UID'];
  status?: Maybe<Scalars['Boolean']>;
}>;


export type SetPageCompletedMutation = (
  { __typename?: 'Mutation' }
  & { setPageCompleted: (
    { __typename?: 'Page' }
    & Pick<Page, 'id'>
  ) }
);

export type SetEventStatusMutationVariables = Exact<{
  id: Scalars['UID'];
  status: EventStatusType;
}>;


export type SetEventStatusMutation = (
  { __typename?: 'Mutation' }
  & { setEventStatus: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'status'>
  ) }
);

export type CreateTrialMutationVariables = Exact<{
  description: Scalars['String'];
}>;


export type CreateTrialMutation = (
  { __typename?: 'Mutation' }
  & { createTrial: (
    { __typename?: 'TrialIdentity' }
    & Pick<TrialIdentity, 'id'>
  ) }
);

export type SetConfigurationValuesMutationVariables = Exact<{
  items: Array<SetConfigurationInput>;
}>;


export type SetConfigurationValuesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setConfigurationValues'>
);

export type CreateOperatorMutationVariables = Exact<{
  description: Scalars['String'];
  role: RoleType;
  site_id?: Maybe<Scalars['UID']>;
  cro_id?: Maybe<Scalars['UID']>;
}>;


export type CreateOperatorMutation = (
  { __typename?: 'Mutation' }
  & { createOperator: (
    { __typename?: 'Operator' }
    & OperatorDetailsFragment
  ) }
);

export type DeleteOperatorMutationVariables = Exact<{
  id: Scalars['UID'];
}>;


export type DeleteOperatorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOperator'>
);

export type InviteOperatorMutationVariables = Exact<{
  email: Scalars['Email'];
  role: RoleType;
  operator_id: Scalars['ID'];
  token: Scalars['String'];
}>;


export type InviteOperatorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteOperator'>
);

export type StoreInviteMutationVariables = Exact<{
  operator_id: Scalars['UID'];
  invite_code: Scalars['String'];
  email: Scalars['EmailAddress'];
}>;


export type StoreInviteMutation = (
  { __typename?: 'Mutation' }
  & { storeInvite: (
    { __typename?: 'Invite' }
    & Pick<Invite, 'id' | 'created_at' | 'updated_at'>
  ) }
);

export type AcceptInviteMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type AcceptInviteMutation = (
  { __typename?: 'Mutation' }
  & { acceptInvite: (
    { __typename?: 'TrialIdentity' }
    & Pick<TrialIdentity, 'id'>
  ) }
);

export type CreateDiaryMutationVariables = Exact<{
  title: Scalars['String'];
  form_id: Scalars['UID'];
  offset: Scalars['Int'];
  repeat_for: Scalars['Int'];
  meta?: Maybe<Scalars['JSON']>;
}>;


export type CreateDiaryMutation = (
  { __typename?: 'Mutation' }
  & { createDiary: (
    { __typename?: 'Diary' }
    & DiaryDetailsFragment
  ) }
);

export type UpdateDiaryMutationVariables = Exact<{
  id: Scalars['UID'];
  data: UpdateDiaryInput;
}>;


export type UpdateDiaryMutation = (
  { __typename?: 'Mutation' }
  & { updateDiary: (
    { __typename?: 'Diary' }
    & DiaryDetailsFragment
  ) }
);

export type DeleteDiaryMutationVariables = Exact<{
  id: Scalars['UID'];
}>;


export type DeleteDiaryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDiary'>
);

export type CreatePatientMutationVariables = Exact<{
  code: Scalars['String'];
  yob: Scalars['Int'];
  gender: Scalars['String'];
}>;


export type CreatePatientMutation = (
  { __typename?: 'Mutation' }
  & { createPatient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'code' | 'gender' | 'yob' | 'status'>
  ) }
);

export type UpdatePatientMutationVariables = Exact<{
  id: Scalars['UID'];
  data: UpdatePatientInput;
}>;


export type UpdatePatientMutation = (
  { __typename?: 'Mutation' }
  & { updatePatient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'code' | 'gender' | 'yob' | 'status'>
  ) }
);

export type CreateQueryMutationVariables = Exact<{
  message: Scalars['String'];
  answer_id: Scalars['UID'];
  section_question_id: Scalars['UID'];
}>;


export type CreateQueryMutation = (
  { __typename?: 'Mutation' }
  & { createQuery: (
    { __typename?: 'AnswerQuery' }
    & QueryDetailsFragment
  ) }
);

export type UpdateQueryMutationVariables = Exact<{
  id: Scalars['UID'];
  data: UpdateQueryInput;
}>;


export type UpdateQueryMutation = (
  { __typename?: 'Mutation' }
  & { updateQuery: (
    { __typename?: 'AnswerQuery' }
    & QueryDetailsFragment
  ) }
);

export type DeleteQueryMutationVariables = Exact<{
  id: Scalars['UID'];
}>;


export type DeleteQueryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteQuery'>
);

export type CreateQueryCommentMutationVariables = Exact<{
  message: Scalars['String'];
  queryId: Scalars['UID'];
}>;


export type CreateQueryCommentMutation = (
  { __typename?: 'Mutation' }
  & { createQueryComment: (
    { __typename?: 'QueryComment' }
    & Pick<QueryComment, 'id' | 'updated_at' | 'message' | 'operator_id' | 'query_id'>
  ), updateQuery: (
    { __typename?: 'AnswerQuery' }
    & QueryDetailsFragment
  ) }
);

export type CreateScheduleMutationVariables = Exact<{
  offset: Scalars['Int'];
  category?: Maybe<Array<ScheduleCategories>>;
  target_id: Array<Scalars['UID']>;
  target_type?: Maybe<Scalars['String']>;
  repeat_for: Scalars['Float'];
  scope?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
}>;


export type CreateScheduleMutation = (
  { __typename?: 'Mutation' }
  & { createSchedule: (
    { __typename?: 'Schedule' }
    & ScheduleDetailsFragment
  ) }
);

export type UpdateScheduleMutationVariables = Exact<{
  id: Scalars['UID'];
  data: UpdateScheduleInput;
}>;


export type UpdateScheduleMutation = (
  { __typename?: 'Mutation' }
  & { updateSchedule: (
    { __typename?: 'Schedule' }
    & ScheduleDetailsFragment
  ) }
);

export type DeleteScheduleMutationVariables = Exact<{
  id: Scalars['UID'];
}>;


export type DeleteScheduleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSchedule'>
);

export type CreateSiteMutationVariables = Exact<{
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  cro_id: Scalars['UID'];
  patient_target: Scalars['Int'];
  authToken: Scalars['String'];
  operator: CreateOperatorInput;
}>;


export type CreateSiteMutation = (
  { __typename?: 'Mutation' }
  & { createSite: (
    { __typename?: 'Site' }
    & { primaryInvestigators: Array<(
      { __typename?: 'Operator' }
      & OperatorDetailsFragment
    )>, operators: Array<(
      { __typename?: 'Operator' }
      & OperatorDetailsFragment
    )> }
    & SiteDetailsFragment
  ) }
);

export type StartTrialMutationVariables = Exact<{ [key: string]: never; }>;


export type StartTrialMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'startTrial'>
);

export type FreezeTrialMutationVariables = Exact<{
  freeze?: Maybe<Scalars['Boolean']>;
}>;


export type FreezeTrialMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'freezeTrial'>
);

export type GetControlPlanQueryVariables = Exact<{ [key: string]: never; }>;


export type GetControlPlanQuery = (
  { __typename?: 'Query' }
  & { getChecks: Array<(
    { __typename?: 'Check' }
    & CheckDetailsFragment
  )> }
);

export type GetCrosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCrosQuery = (
  { __typename?: 'Query' }
  & { getCros: Array<(
    { __typename?: 'Cro' }
    & Pick<Cro, 'id' | 'code' | 'description' | 'created_at' | 'updated_at'>
    & { sites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'id' | 'code' | 'description'>
    )> }
  )> }
);

export type GetCroQueryVariables = Exact<{
  id: Scalars['UID'];
}>;


export type GetCroQuery = (
  { __typename?: 'Query' }
  & { getCro?: Maybe<(
    { __typename?: 'Cro' }
    & { directors: Array<(
      { __typename?: 'Operator' }
      & OperatorDetailsFragment
    )> }
    & CroDetailsFragment
  )> }
);

export type GetEnrollmentStepsQueryVariables = Exact<{
  patient_id?: Maybe<Scalars['UID']>;
  includeEvent?: Maybe<Scalars['Boolean']>;
}>;


export type GetEnrollmentStepsQuery = (
  { __typename?: 'Query' }
  & { getEnrollmentSteps: Array<(
    { __typename?: 'EnrollmentStep' }
    & Pick<EnrollmentStep, 'allowed' | 'active' | 'disables' | 'group' | 'set_status' | 'step'>
    & { event?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'status' | 'completed_pages'>
    )>, schedule?: Maybe<(
      { __typename?: 'Schedule' }
      & Pick<Schedule, 'id' | 'updated_at' | 'scope' | 'target_id'>
      & { schedulable: Array<(
        { __typename?: 'Form' }
        & FormDetailsFragment
      )> }
    )> }
  )> }
);

export type GetEnrollmentStepsStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEnrollmentStepsStatusQuery = (
  { __typename?: 'Query' }
  & { getEnrollmentSteps: Array<(
    { __typename?: 'EnrollmentStep' }
    & Pick<EnrollmentStep, 'set_status' | 'step'>
  )> }
);

export type GetEnrollmentStepsConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEnrollmentStepsConfigQuery = (
  { __typename?: 'Query' }
  & { getEnrollmentSteps: Array<(
    { __typename?: 'EnrollmentStep' }
    & Pick<EnrollmentStep, 'active' | 'set_status' | 'step'>
  )> }
);

export type GetEventsQueryVariables = Exact<{
  filter?: Maybe<FindEventFilterInput>;
  order?: Maybe<OrderEventsInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetEventsQuery = (
  { __typename?: 'Query' }
  & { getEvents: (
    { __typename?: 'EventsPaginated' }
    & { items: Array<(
      { __typename?: 'Event' }
      & EventDetailsFragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'hasMore' | 'total'>
    ) }
  ) }
);

export type GetEventQueryVariables = Exact<{
  id: Scalars['UID'];
  includeSchedule?: Maybe<Scalars['Boolean']>;
  includeQuestionnaire?: Maybe<Scalars['Boolean']>;
  includeDiary?: Maybe<Scalars['Boolean']>;
  includePatient?: Maybe<Scalars['Boolean']>;
}>;


export type GetEventQuery = (
  { __typename?: 'Query' }
  & { getEvent?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'completed_at' | 'completed_pages' | 'persisted' | 'schedule_id' | 'patient_id' | 'form_ids' | 'status' | 'updated_at'>
    & { questionnaire?: Maybe<(
      { __typename?: 'Form' }
      & FormDetailsFragment
    )>, schedule?: Maybe<(
      { __typename?: 'Schedule' }
      & ScheduleDetailsFragment
    )>, diary?: Maybe<(
      { __typename?: 'Diary' }
      & DiaryDetailsFragment
    )>, patient: (
      { __typename?: 'Patient' }
      & PatientDetailsFragment
    ) }
  )> }
);

export type GetFormsTitleQueryVariables = Exact<{
  filter?: Maybe<FindFormFilterInput>;
}>;


export type GetFormsTitleQuery = (
  { __typename?: 'Query' }
  & { getForms: Array<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'title'>
  )> }
);

export type GetFormsListQueryVariables = Exact<{
  filter?: Maybe<FindFormFilterInput>;
  order?: Maybe<OrderFormInput>;
}>;


export type GetFormsListQuery = (
  { __typename?: 'Query' }
  & { getForms: Array<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'title' | 'draft' | 'type' | 'updated_at'>
  )> }
);

export type GetFormQueryVariables = Exact<{
  id: Scalars['UID'];
  pageId?: Maybe<Scalars['UID']>;
  fetchAnswer?: Scalars['Boolean'];
  patientId?: Maybe<Scalars['UID']>;
  eventId?: Maybe<Scalars['UID']>;
}>;


export type GetFormQuery = (
  { __typename?: 'Query' }
  & { getForm?: Maybe<(
    { __typename?: 'Form' }
    & { page?: Maybe<(
      { __typename?: 'Page' }
      & PageDetailsFragment
    )> }
    & FormDetailsFragment
  )> }
);

export type GetAvailableTrialsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAvailableTrialsQuery = (
  { __typename?: 'Query' }
  & { getAvailableTrials: Array<(
    { __typename?: 'TrialIdentity' }
    & Pick<TrialIdentity, 'id' | 'description' | 'status' | 'info' | 'role' | 'scopes'>
  )> }
);

export type ExchangeTokenQueryVariables = Exact<{
  uuid: Scalars['UID'];
}>;


export type ExchangeTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'exchangeToken'>
);

export type GetMonitorProgressQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMonitorProgressQuery = (
  { __typename?: 'Query' }
  & { getMonitorProgress: (
    { __typename?: 'MonitorProgress' }
    & Pick<MonitorProgress, 'totalEvents' | 'totalPatients' | 'totalQueries'>
    & { events: Array<(
      { __typename?: 'MonitorProgressEvent' }
      & Pick<MonitorProgressEvent, 'total' | 'type'>
    )>, queries: Array<(
      { __typename?: 'MonitorProgressQuery' }
      & Pick<MonitorProgressQuery, 'total' | 'type'>
    )> }
  ) }
);

export type CurrentOperatorQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentOperatorQuery = (
  { __typename?: 'Query' }
  & { currentOperator: (
    { __typename?: 'Operator' }
    & { cro?: Maybe<(
      { __typename?: 'Cro' }
      & CroDetailsFragment
    )>, site?: Maybe<(
      { __typename?: 'Site' }
      & SiteDetailsFragment
    )> }
    & OperatorDetailsFragment
  ) }
);

export type GetDiariesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDiariesQuery = (
  { __typename?: 'Query' }
  & { getDiaries: Array<(
    { __typename?: 'Diary' }
    & DiaryDetailsFragment
  )> }
);

export type GetPatientsQueryVariables = Exact<{
  site_id?: Maybe<Scalars['UID']>;
  cro_id?: Maybe<Scalars['UID']>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  order?: Maybe<OrderPatientsInput>;
}>;


export type GetPatientsQuery = (
  { __typename?: 'Query' }
  & { getPatients: (
    { __typename?: 'PatientsPaginated' }
    & { items: Array<(
      { __typename?: 'Patient' }
      & PatientDetailsFragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'hasMore' | 'total'>
    ) }
  ), getEnrollmentSteps: Array<(
    { __typename?: 'EnrollmentStep' }
    & Pick<EnrollmentStep, 'set_status' | 'step'>
  )> }
);

export type GetPatientQueryVariables = Exact<{
  patient_uuid: Scalars['UID'];
  date_start?: Maybe<Scalars['DateTime']>;
  date_end?: Maybe<Scalars['DateTime']>;
  includeDiaries?: Maybe<Scalars['Boolean']>;
  includeQuestionnaires?: Maybe<Scalars['Boolean']>;
  includeEvents?: Maybe<Scalars['Boolean']>;
  includeCommonForms?: Maybe<Scalars['Boolean']>;
  includeEnrollment?: Maybe<Scalars['Boolean']>;
}>;


export type GetPatientQuery = (
  { __typename?: 'Query' }
  & { getPatient?: Maybe<(
    { __typename?: 'Patient' }
    & { enrollment: Array<(
      { __typename?: 'Event' }
      & { schedule?: Maybe<(
        { __typename?: 'Schedule' }
        & SchedulePreviewFragment
      )> }
      & EventPreviewFragment
    )>, common_forms: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'schedule_id'>
      & { questionnaire?: Maybe<(
        { __typename?: 'Form' }
        & Pick<Form, 'id' | 'title'>
      )>, schedule?: Maybe<(
        { __typename?: 'Schedule' }
        & SchedulePreviewFragment
      )> }
      & EventPreviewFragment
    )>, diaries: Array<(
      { __typename?: 'Event' }
      & { diary?: Maybe<(
        { __typename?: 'Diary' }
        & Pick<Diary, 'id' | 'title' | 'form_id'>
      )> }
      & EventPreviewFragment
    )>, questionnaires: Array<(
      { __typename?: 'Event' }
      & { questionnaire?: Maybe<(
        { __typename?: 'Form' }
        & Pick<Form, 'id' | 'title'>
      )> }
      & EventPreviewFragment
    )>, events: Array<(
      { __typename?: 'Event' }
      & { schedule?: Maybe<(
        { __typename?: 'Schedule' }
        & SchedulePreviewFragment
      )> }
      & EventPreviewFragment
    )> }
    & PatientDetailsFragment
  )> }
);

export type GetPatientPreviewQueryVariables = Exact<{
  patient_uuid: Scalars['UID'];
}>;


export type GetPatientPreviewQuery = (
  { __typename?: 'Query' }
  & { getPatient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { common_forms: Array<(
      { __typename?: 'Event' }
      & { questionnaire?: Maybe<(
        { __typename?: 'Form' }
        & Pick<Form, 'id' | 'title'>
      )>, schedule?: Maybe<(
        { __typename?: 'Schedule' }
        & SchedulePreviewFragment
      )> }
      & EventPreviewFragment
    )>, enrollment: Array<(
      { __typename?: 'Event' }
      & { schedule?: Maybe<(
        { __typename?: 'Schedule' }
        & SchedulePreviewFragment
      )> }
      & EventPreviewFragment
    )>, events: Array<(
      { __typename?: 'Event' }
      & { child_events: Array<(
        { __typename?: 'Event' }
        & { schedule?: Maybe<(
          { __typename?: 'Schedule' }
          & SchedulePreviewFragment
        )> }
        & EventPreviewFragment
      )>, schedule?: Maybe<(
        { __typename?: 'Schedule' }
        & SchedulePreviewFragment
      )> }
      & EventPreviewFragment
    )> }
  )> }
);

export type GetQueriesCountQueryVariables = Exact<{
  filter?: Maybe<FindQueriesFiltersInput>;
}>;


export type GetQueriesCountQuery = (
  { __typename?: 'Query' }
  & { getQueries: (
    { __typename?: 'QueriesPaginated' }
    & { paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'total'>
    ) }
  ) }
);

export type GetQueriesQueryVariables = Exact<{
  filter?: Maybe<FindQueriesFiltersInput>;
  order?: Maybe<OrderQueriesInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetQueriesQuery = (
  { __typename?: 'Query' }
  & { getQueries: (
    { __typename?: 'QueriesPaginated' }
    & { items: Array<(
      { __typename?: 'AnswerQuery' }
      & QueryDetailsFragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'hasMore' | 'total'>
    ) }
  ) }
);

export type GetQueryQueryVariables = Exact<{
  id: Scalars['UID'];
}>;


export type GetQueryQuery = (
  { __typename?: 'Query' }
  & { getQuery?: Maybe<(
    { __typename?: 'AnswerQuery' }
    & QueryDetailsFragment
  )> }
);

export type GetSchedulesQueryVariables = Exact<{
  filter?: Maybe<FindSchedulesFiltersInput>;
  order?: Maybe<OrderSchedulesInput>;
}>;


export type GetSchedulesQuery = (
  { __typename?: 'Query' }
  & { getSchedules: Array<(
    { __typename?: 'Schedule' }
    & ScheduleDetailsFragment
  )> }
);

export type GetScheduleQueryVariables = Exact<{
  id: Scalars['UID'];
}>;


export type GetScheduleQuery = (
  { __typename?: 'Query' }
  & { getSchedule?: Maybe<(
    { __typename?: 'Schedule' }
    & ScheduleDetailsFragment
  )> }
);

export type GetSiteQueryVariables = Exact<{
  id: Scalars['UID'];
}>;


export type GetSiteQuery = (
  { __typename?: 'Query' }
  & { getSite?: Maybe<(
    { __typename?: 'Site' }
    & { primaryInvestigators: Array<(
      { __typename?: 'Operator' }
      & OperatorDetailsFragment
    )>, operators: Array<(
      { __typename?: 'Operator' }
      & OperatorDetailsFragment
    )> }
    & SiteDetailsFragment
  )> }
);

export type ConfigurationStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type ConfigurationStatusQuery = (
  { __typename?: 'Query' }
  & { configurationStatus: (
    { __typename?: 'ConfigurationStatus' }
    & Pick<ConfigurationStatus, 'completed' | 'current' | 'hint' | 'status' | 'total'>
  ) }
);

export type GetConfigurationValuesQueryVariables = Exact<{
  fieldnames?: Maybe<Array<ConfigurationName>>;
}>;


export type GetConfigurationValuesQuery = (
  { __typename?: 'Query' }
  & { getConfigurationValues: Array<(
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'fieldname' | 'fieldvalue' | 'fieldtype'>
  )> }
);

export const QueryDetailsFragmentDoc = gql`
    fragment QueryDetails on AnswerQuery {
  id
  section_question_id
  answer_id
  event_id
  event {
    patient {
      site {
        code
      }
    }
  }
  comments {
    id
    message
    updated_at
    operator_id
  }
  status
  updated_at
}
    `;
export const PageDetailsFragmentDoc = gql`
    fragment PageDetails on Page {
  id
  title
  sections {
    id
    order
    columns
    page_id
    repeatable
    title
    depends_definition
    depends_on
    questions {
      id
      order
      rendertype
      section_id
      depends_on
      depends_definition
      answers(patient_id: $patientId, event_id: $eventId) @include(if: $fetchAnswer) {
        id
        section_question_id
        value
        updated_at
        query {
          ...QueryDetails
        }
      }
      question {
        id
        code
        label
        definitions
        rendertype
        required
        sharable
        valuetype
      }
    }
  }
}
    ${QueryDetailsFragmentDoc}`;
export const FormDetailsFragmentDoc = gql`
    fragment FormDetails on Form {
  id
  title
  created_at
  type
  draft
  pagesInfo {
    id
    title
  }
}
    `;
export const OperatorDetailsFragmentDoc = gql`
    fragment OperatorDetails on Operator {
  id
  description
  role
  created_at
}
    `;
export const SiteDetailsFragmentDoc = gql`
    fragment SiteDetails on Site {
  id
  description
  code
  created_at
  cro_id
  patient_target
  patients_enrolled
  progress {
    completed
    total
  }
  operators {
    ...OperatorDetails
  }
}
    ${OperatorDetailsFragmentDoc}`;
export const CroDetailsFragmentDoc = gql`
    fragment CroDetails on Cro {
  id
  description
  code
  created_at
  patient_target
  patients_enrolled
  sites {
    ...SiteDetails
  }
  operators {
    ...OperatorDetails
  }
}
    ${SiteDetailsFragmentDoc}
${OperatorDetailsFragmentDoc}`;
export const SchedulePreviewFragmentDoc = gql`
    fragment SchedulePreview on Schedule {
  id
  title
  target_id
  enable_form_ids
  enable_forms {
    id
    title
  }
}
    `;
export const PatientDetailsFragmentDoc = gql`
    fragment PatientDetails on Patient {
  id
  code
  yob
  status
  dropped_at
  site_id
  gender
  started_at
  created_at
  updated_at
  site {
    id
    code
  }
}
    `;
export const EventPreviewFragmentDoc = gql`
    fragment EventPreview on Event {
  created_at
  completed_at
  status
  id
  child_events {
    id
    form_ids
  }
  persisted
  form_ids
  updated_at
}
    `;
export const ScheduleDetailsFragmentDoc = gql`
    fragment ScheduleDetails on Schedule {
  id
  updated_at
  offset
  target_id
  target_type
  repeat_for
  enable_form_ids
  required
  scope
  meta
  title
}
    `;
export const EventDetailsFragmentDoc = gql`
    fragment EventDetails on Event {
  created_at
  completed_at
  completed_pages
  status
  persisted
  id
  persisted
  patient {
    id
    code
    site {
      id
      code
    }
  }
  questionnaire {
    title
  }
  diary {
    title
  }
  schedule {
    ...ScheduleDetails
  }
  updated_at
}
    ${ScheduleDetailsFragmentDoc}`;
export const DiaryDetailsFragmentDoc = gql`
    fragment DiaryDetails on Diary {
  id
  updated_at
  offset
  form_id
  repeat_for
  meta
  title
}
    `;
export const CheckDetailsFragmentDoc = gql`
    fragment CheckDetails on Check {
  id
  categories
  strategies {
    strategy
    strategy_arguments
  }
  meta
  title
  updated_at
}
    `;
export const TrialUserDataFragmentDoc = gql`
    fragment TrialUserData on TrialUser {
  iss
  iat
  exp
  sub
  role
  scopes
  status
  hash
}
    `;
export const GetTrialCredDocument = gql`
    query GetTrialCred {
  trialCred @client {
    hash
    token
    id
    user @client {
      ...TrialUserData
    }
  }
}
    ${TrialUserDataFragmentDoc}`;
export type GetTrialCredQueryResult = ApolloReactCommon.QueryResult<GetTrialCredQuery, GetTrialCredQueryVariables>;
export const CreateControlPlanDocument = gql`
    mutation CreateControlPlan($title: String!, $categories: [String!], $meta: JSON, $strategies: [CreateCheckStrategyInput!]!) {
  createCheck(title: $title, categories: $categories, strategies: $strategies, meta: $meta) {
    ...CheckDetails
  }
}
    ${CheckDetailsFragmentDoc}`;
export type CreateControlPlanMutationResult = ApolloReactCommon.MutationResult<CreateControlPlanMutation>;
export type CreateControlPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateControlPlanMutation, CreateControlPlanMutationVariables>;
export const UpdateControlPlanDocument = gql`
    mutation UpdateControlPlan($id: UID!, $data: UpdateCheckInput!) {
  updateCheck(id: $id, data: $data) {
    ...CheckDetails
  }
}
    ${CheckDetailsFragmentDoc}`;
export type UpdateControlPlanMutationResult = ApolloReactCommon.MutationResult<UpdateControlPlanMutation>;
export type UpdateControlPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateControlPlanMutation, UpdateControlPlanMutationVariables>;
export const DeleteControlPlanDocument = gql`
    mutation DeleteControlPlan($id: UID!) {
  deleteCheck(id: $id)
}
    `;
export type DeleteControlPlanMutationResult = ApolloReactCommon.MutationResult<DeleteControlPlanMutation>;
export type DeleteControlPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteControlPlanMutation, DeleteControlPlanMutationVariables>;
export const CreateCroDocument = gql`
    mutation createCro($description: String, $code: String!, $operator: CreateOperatorInput!, $authToken: String!) {
  createCro(description: $description, code: $code, operator: $operator, authToken: $authToken) {
    id
    code
    description
    created_at
    updated_at
    directors {
      ...OperatorDetails
    }
    operators {
      ...OperatorDetails
    }
    sites {
      id
      code
      description
    }
  }
}
    ${OperatorDetailsFragmentDoc}`;
export type CreateCroMutationResult = ApolloReactCommon.MutationResult<CreateCroMutation>;
export type CreateCroMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCroMutation, CreateCroMutationVariables>;
export const CompleteStepDocument = gql`
    mutation CompleteStep($patient_id: UID!, $data: UpdatePatientInput!, $event_id: UID!, $status: EventStatusType!) {
  updatePatient(id: $patient_id, data: $data) {
    id
    code
    gender
    yob
    status
  }
  setEventStatus(id: $event_id, status: $status) {
    id
    status
  }
}
    `;
export type CompleteStepMutationResult = ApolloReactCommon.MutationResult<CompleteStepMutation>;
export type CompleteStepMutationOptions = ApolloReactCommon.BaseMutationOptions<CompleteStepMutation, CompleteStepMutationVariables>;
export const SetEnrollmentStepDocument = gql`
    mutation SetEnrollmentStep($step: Int!, $data: SetEnrollmentStepInput!) {
  setEnrollmentStep(step: $step, data: $data) {
    active
  }
}
    `;
export type SetEnrollmentStepMutationResult = ApolloReactCommon.MutationResult<SetEnrollmentStepMutation>;
export type SetEnrollmentStepMutationOptions = ApolloReactCommon.BaseMutationOptions<SetEnrollmentStepMutation, SetEnrollmentStepMutationVariables>;
export const CreateEventDocument = gql`
    mutation CreateEvent($patient_id: UID!, $form_id: UID!, $parent_id: UID) {
  createEvent(patient_id: $patient_id, form_id: $form_id, parent_id: $parent_id) {
    id
  }
}
    `;
export type CreateEventMutationResult = ApolloReactCommon.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const CreateFormDocument = gql`
    mutation CreateForm($title: String!) {
  createForm(title: $title) {
    id
    title
  }
}
    `;
export type CreateFormMutationResult = ApolloReactCommon.MutationResult<CreateFormMutation>;
export type CreateFormMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFormMutation, CreateFormMutationVariables>;
export const UpdateFormDocument = gql`
    mutation UpdateForm($id: UID!, $title: String, $draft: Boolean, $type: FormTypeEnum, $categories: [String!]) {
  updateForm(id: $id, data: {title: $title, type: $type, draft: $draft, categories: $categories}) {
    id
    title
    categories
    draft
    type
  }
}
    `;
export type UpdateFormMutationResult = ApolloReactCommon.MutationResult<UpdateFormMutation>;
export type UpdateFormMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFormMutation, UpdateFormMutationVariables>;
export const CreatePageDocument = gql`
    mutation CreatePage($form_id: UID!, $data: CreatePageInput!) {
  createPage(form_id: $form_id, data: $data) {
    id
    title
    columns
    order
    sections {
      id
    }
  }
}
    `;
export type CreatePageMutationResult = ApolloReactCommon.MutationResult<CreatePageMutation>;
export type CreatePageMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePageMutation, CreatePageMutationVariables>;
export const UpdatePageDocument = gql`
    mutation UpdatePage($id: UID!, $data: UpdatePageInput!) {
  updatePage(id: $id, data: $data) {
    id
    title
    columns
    order
  }
}
    `;
export type UpdatePageMutationResult = ApolloReactCommon.MutationResult<UpdatePageMutation>;
export type UpdatePageMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePageMutation, UpdatePageMutationVariables>;
export const DeletePageDocument = gql`
    mutation DeletePage($id: UID!) {
  deletePage(id: $id)
}
    `;
export type DeletePageMutationResult = ApolloReactCommon.MutationResult<DeletePageMutation>;
export type DeletePageMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePageMutation, DeletePageMutationVariables>;
export const CreateSectionDocument = gql`
    mutation CreateSection($id: UID!, $data: CreateSectionInput!) {
  createSection(pageId: $id, data: $data) {
    id
    title
    columns
    order
    questions {
      id
    }
  }
}
    `;
export type CreateSectionMutationResult = ApolloReactCommon.MutationResult<CreateSectionMutation>;
export type CreateSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSectionMutation, CreateSectionMutationVariables>;
export const UpdateSectionDocument = gql`
    mutation UpdateSection($id: UID!, $data: CreateSectionInput!) {
  updateSection(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateSectionMutationResult = ApolloReactCommon.MutationResult<UpdateSectionMutation>;
export type UpdateSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSectionMutation, UpdateSectionMutationVariables>;
export const DeleteSectionDocument = gql`
    mutation DeleteSection($id: UID!) {
  deleteSection(id: $id)
}
    `;
export type DeleteSectionMutationResult = ApolloReactCommon.MutationResult<DeleteSectionMutation>;
export type DeleteSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSectionMutation, DeleteSectionMutationVariables>;
export const CreateQuestionDocument = gql`
    mutation CreateQuestion($data: CreateQuestionInput!) {
  createQuestion(data: $data) {
    id
    label
    rendertype
    required
    sharable
    valuetype
    definitions
  }
}
    `;
export type CreateQuestionMutationResult = ApolloReactCommon.MutationResult<CreateQuestionMutation>;
export type CreateQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateQuestionMutation, CreateQuestionMutationVariables>;
export const UpdateQuestionDocument = gql`
    mutation UpdateQuestion($id: UID!, $data: UpdateQuestionInput!) {
  updateQuestion(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateQuestionMutationResult = ApolloReactCommon.MutationResult<UpdateQuestionMutation>;
export type UpdateQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>;
export const DeleteQuestionDocument = gql`
    mutation DeleteQuestion($id: UID!) {
  deleteQuestion(id: $id)
}
    `;
export type DeleteQuestionMutationResult = ApolloReactCommon.MutationResult<DeleteQuestionMutation>;
export type DeleteQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>;
export const CreateSectionQuestionDocument = gql`
    mutation CreateSectionQuestion($questionId: UID!, $sectionId: UID!, $data: SetSectionQuestionInput!) {
  createSectionQuestion(questionId: $questionId, sectionId: $sectionId, data: $data) {
    id
    order
    depends_definition
    depends_on
  }
}
    `;
export type CreateSectionQuestionMutationResult = ApolloReactCommon.MutationResult<CreateSectionQuestionMutation>;
export type CreateSectionQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSectionQuestionMutation, CreateSectionQuestionMutationVariables>;
export const UpdateSectionQuestionDocument = gql`
    mutation UpdateSectionQuestion($id: UID!, $data: SetSectionQuestionInput!, $sectionId: UID) {
  updateSectionQuestion(id: $id, data: $data, sectionId: $sectionId) {
    id
  }
}
    `;
export type UpdateSectionQuestionMutationResult = ApolloReactCommon.MutationResult<UpdateSectionQuestionMutation>;
export type UpdateSectionQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSectionQuestionMutation, UpdateSectionQuestionMutationVariables>;
export const SaveFormAnswersDocument = gql`
    mutation SaveFormAnswers($form_id: UID!, $data: [SaveAnswerInput!]!, $patient_id: UID!, $event_id: UID!) {
  saveFormAnswers(data: $data, patient_id: $patient_id, form_id: $form_id, event_id: $event_id) {
    id
    value
  }
}
    `;
export type SaveFormAnswersMutationResult = ApolloReactCommon.MutationResult<SaveFormAnswersMutation>;
export type SaveFormAnswersMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveFormAnswersMutation, SaveFormAnswersMutationVariables>;
export const SetPageCompletedDocument = gql`
    mutation SetPageCompleted($page_id: UID!, $form_id: UID!, $event_id: UID!, $status: Boolean) {
  setPageCompleted(form_id: $form_id, page_id: $page_id, status: $status, event_id: $event_id) {
    id
  }
}
    `;
export type SetPageCompletedMutationResult = ApolloReactCommon.MutationResult<SetPageCompletedMutation>;
export type SetPageCompletedMutationOptions = ApolloReactCommon.BaseMutationOptions<SetPageCompletedMutation, SetPageCompletedMutationVariables>;
export const SetEventStatusDocument = gql`
    mutation SetEventStatus($id: UID!, $status: EventStatusType!) {
  setEventStatus(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type SetEventStatusMutationResult = ApolloReactCommon.MutationResult<SetEventStatusMutation>;
export type SetEventStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<SetEventStatusMutation, SetEventStatusMutationVariables>;
export const CreateTrialDocument = gql`
    mutation createTrial($description: String!) {
  createTrial(description: $description) {
    id
  }
}
    `;
export type CreateTrialMutationResult = ApolloReactCommon.MutationResult<CreateTrialMutation>;
export type CreateTrialMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTrialMutation, CreateTrialMutationVariables>;
export const SetConfigurationValuesDocument = gql`
    mutation setConfigurationValues($items: [SetConfigurationInput!]!) {
  setConfigurationValues(items: $items)
}
    `;
export type SetConfigurationValuesMutationResult = ApolloReactCommon.MutationResult<SetConfigurationValuesMutation>;
export type SetConfigurationValuesMutationOptions = ApolloReactCommon.BaseMutationOptions<SetConfigurationValuesMutation, SetConfigurationValuesMutationVariables>;
export const CreateOperatorDocument = gql`
    mutation createOperator($description: String!, $role: RoleType!, $site_id: UID, $cro_id: UID) {
  createOperator(description: $description, role: $role, site_id: $site_id, cro_id: $cro_id) {
    ...OperatorDetails
  }
}
    ${OperatorDetailsFragmentDoc}`;
export type CreateOperatorMutationResult = ApolloReactCommon.MutationResult<CreateOperatorMutation>;
export type CreateOperatorMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOperatorMutation, CreateOperatorMutationVariables>;
export const DeleteOperatorDocument = gql`
    mutation deleteOperator($id: UID!) {
  deleteOperator(id: $id)
}
    `;
export type DeleteOperatorMutationResult = ApolloReactCommon.MutationResult<DeleteOperatorMutation>;
export type DeleteOperatorMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOperatorMutation, DeleteOperatorMutationVariables>;
export const InviteOperatorDocument = gql`
    mutation inviteOperator($email: Email!, $role: RoleType!, $operator_id: ID!, $token: String!) {
  inviteOperator(email: $email, role: $role, operator_id: $operator_id, token: $token)
}
    `;
export type InviteOperatorMutationResult = ApolloReactCommon.MutationResult<InviteOperatorMutation>;
export type InviteOperatorMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteOperatorMutation, InviteOperatorMutationVariables>;
export const StoreInviteDocument = gql`
    mutation storeInvite($operator_id: UID!, $invite_code: String!, $email: EmailAddress!) {
  storeInvite(operator_id: $operator_id, invite_code: $invite_code, email: $email) {
    id
    created_at
    updated_at
  }
}
    `;
export type StoreInviteMutationResult = ApolloReactCommon.MutationResult<StoreInviteMutation>;
export type StoreInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<StoreInviteMutation, StoreInviteMutationVariables>;
export const AcceptInviteDocument = gql`
    mutation acceptInvite($code: String!) {
  acceptInvite(code: $code) {
    id
  }
}
    `;
export type AcceptInviteMutationResult = ApolloReactCommon.MutationResult<AcceptInviteMutation>;
export type AcceptInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptInviteMutation, AcceptInviteMutationVariables>;
export const CreateDiaryDocument = gql`
    mutation CreateDiary($title: String!, $form_id: UID!, $offset: Int!, $repeat_for: Int!, $meta: JSON) {
  createDiary(title: $title, form_id: $form_id, offset: $offset, repeat_for: $repeat_for, meta: $meta) {
    ...DiaryDetails
  }
}
    ${DiaryDetailsFragmentDoc}`;
export type CreateDiaryMutationResult = ApolloReactCommon.MutationResult<CreateDiaryMutation>;
export type CreateDiaryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDiaryMutation, CreateDiaryMutationVariables>;
export const UpdateDiaryDocument = gql`
    mutation UpdateDiary($id: UID!, $data: UpdateDiaryInput!) {
  updateDiary(id: $id, data: $data) {
    ...DiaryDetails
  }
}
    ${DiaryDetailsFragmentDoc}`;
export type UpdateDiaryMutationResult = ApolloReactCommon.MutationResult<UpdateDiaryMutation>;
export type UpdateDiaryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDiaryMutation, UpdateDiaryMutationVariables>;
export const DeleteDiaryDocument = gql`
    mutation DeleteDiary($id: UID!) {
  deleteDiary(id: $id)
}
    `;
export type DeleteDiaryMutationResult = ApolloReactCommon.MutationResult<DeleteDiaryMutation>;
export type DeleteDiaryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDiaryMutation, DeleteDiaryMutationVariables>;
export const CreatePatientDocument = gql`
    mutation CreatePatient($code: String!, $yob: Int!, $gender: String!) {
  createPatient(code: $code, yob: $yob, gender: $gender) {
    id
    code
    gender
    yob
    status
  }
}
    `;
export type CreatePatientMutationResult = ApolloReactCommon.MutationResult<CreatePatientMutation>;
export type CreatePatientMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePatientMutation, CreatePatientMutationVariables>;
export const UpdatePatientDocument = gql`
    mutation UpdatePatient($id: UID!, $data: UpdatePatientInput!) {
  updatePatient(id: $id, data: $data) {
    id
    code
    gender
    yob
    status
  }
}
    `;
export type UpdatePatientMutationResult = ApolloReactCommon.MutationResult<UpdatePatientMutation>;
export type UpdatePatientMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePatientMutation, UpdatePatientMutationVariables>;
export const CreateQueryDocument = gql`
    mutation CreateQuery($message: String!, $answer_id: UID!, $section_question_id: UID!) {
  createQuery(answer_id: $answer_id, message: $message, section_question_id: $section_question_id) {
    ...QueryDetails
  }
}
    ${QueryDetailsFragmentDoc}`;
export type CreateQueryMutationResult = ApolloReactCommon.MutationResult<CreateQueryMutation>;
export type CreateQueryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateQueryMutation, CreateQueryMutationVariables>;
export const UpdateQueryDocument = gql`
    mutation UpdateQuery($id: UID!, $data: UpdateQueryInput!) {
  updateQuery(id: $id, data: $data) {
    ...QueryDetails
  }
}
    ${QueryDetailsFragmentDoc}`;
export type UpdateQueryMutationResult = ApolloReactCommon.MutationResult<UpdateQueryMutation>;
export type UpdateQueryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateQueryMutation, UpdateQueryMutationVariables>;
export const DeleteQueryDocument = gql`
    mutation DeleteQuery($id: UID!) {
  deleteQuery(id: $id)
}
    `;
export type DeleteQueryMutationResult = ApolloReactCommon.MutationResult<DeleteQueryMutation>;
export type DeleteQueryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteQueryMutation, DeleteQueryMutationVariables>;
export const CreateQueryCommentDocument = gql`
    mutation CreateQueryComment($message: String!, $queryId: UID!) {
  createQueryComment(message: $message, query_id: $queryId) {
    id
    updated_at
    message
    operator_id
    query_id
  }
  updateQuery(id: $queryId, data: {status: OPEN}) {
    ...QueryDetails
  }
}
    ${QueryDetailsFragmentDoc}`;
export type CreateQueryCommentMutationResult = ApolloReactCommon.MutationResult<CreateQueryCommentMutation>;
export type CreateQueryCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateQueryCommentMutation, CreateQueryCommentMutationVariables>;
export const CreateScheduleDocument = gql`
    mutation CreateSchedule($offset: Int!, $category: [ScheduleCategories!], $target_id: [UID!]!, $target_type: String, $repeat_for: Float!, $scope: String, $title: String!, $meta: JSON) {
  createSchedule(categories: $category, offset: $offset, target_id: $target_id, target_type: $target_type, repeat_for: $repeat_for, scope: $scope, title: $title, meta: $meta) {
    ...ScheduleDetails
  }
}
    ${ScheduleDetailsFragmentDoc}`;
export type CreateScheduleMutationResult = ApolloReactCommon.MutationResult<CreateScheduleMutation>;
export type CreateScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateScheduleMutation, CreateScheduleMutationVariables>;
export const UpdateScheduleDocument = gql`
    mutation UpdateSchedule($id: UID!, $data: UpdateScheduleInput!) {
  updateSchedule(id: $id, data: $data) {
    ...ScheduleDetails
  }
}
    ${ScheduleDetailsFragmentDoc}`;
export type UpdateScheduleMutationResult = ApolloReactCommon.MutationResult<UpdateScheduleMutation>;
export type UpdateScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateScheduleMutation, UpdateScheduleMutationVariables>;
export const DeleteScheduleDocument = gql`
    mutation DeleteSchedule($id: UID!) {
  deleteSchedule(id: $id)
}
    `;
export type DeleteScheduleMutationResult = ApolloReactCommon.MutationResult<DeleteScheduleMutation>;
export type DeleteScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteScheduleMutation, DeleteScheduleMutationVariables>;
export const CreateSiteDocument = gql`
    mutation createSite($code: String!, $description: String, $cro_id: UID!, $patient_target: Int!, $authToken: String!, $operator: CreateOperatorInput!) {
  createSite(code: $code, description: $description, cro_id: $cro_id, patient_target: $patient_target, authToken: $authToken, operator: $operator) {
    ...SiteDetails
    primaryInvestigators {
      ...OperatorDetails
    }
    operators {
      ...OperatorDetails
    }
  }
}
    ${SiteDetailsFragmentDoc}
${OperatorDetailsFragmentDoc}`;
export type CreateSiteMutationResult = ApolloReactCommon.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteMutation, CreateSiteMutationVariables>;
export const StartTrialDocument = gql`
    mutation StartTrial {
  startTrial
}
    `;
export type StartTrialMutationResult = ApolloReactCommon.MutationResult<StartTrialMutation>;
export type StartTrialMutationOptions = ApolloReactCommon.BaseMutationOptions<StartTrialMutation, StartTrialMutationVariables>;
export const FreezeTrialDocument = gql`
    mutation FreezeTrial($freeze: Boolean) {
  freezeTrial(freeze: $freeze)
}
    `;
export type FreezeTrialMutationResult = ApolloReactCommon.MutationResult<FreezeTrialMutation>;
export type FreezeTrialMutationOptions = ApolloReactCommon.BaseMutationOptions<FreezeTrialMutation, FreezeTrialMutationVariables>;
export const GetControlPlanDocument = gql`
    query GetControlPlan {
  getChecks {
    ...CheckDetails
  }
}
    ${CheckDetailsFragmentDoc}`;
export type GetControlPlanQueryResult = ApolloReactCommon.QueryResult<GetControlPlanQuery, GetControlPlanQueryVariables>;
export const GetCrosDocument = gql`
    query GetCros {
  getCros {
    id
    code
    description
    created_at
    updated_at
    sites {
      id
      code
      description
    }
  }
}
    `;
export type GetCrosQueryResult = ApolloReactCommon.QueryResult<GetCrosQuery, GetCrosQueryVariables>;
export const GetCroDocument = gql`
    query GetCro($id: UID!) {
  getCro(id: $id) {
    ...CroDetails
    directors {
      ...OperatorDetails
    }
  }
}
    ${CroDetailsFragmentDoc}
${OperatorDetailsFragmentDoc}`;
export type GetCroQueryResult = ApolloReactCommon.QueryResult<GetCroQuery, GetCroQueryVariables>;
export const GetEnrollmentStepsDocument = gql`
    query GetEnrollmentSteps($patient_id: UID, $includeEvent: Boolean = false) {
  getEnrollmentSteps {
    allowed
    active
    disables
    group
    event(patient_id: $patient_id) @include(if: $includeEvent) {
      id
      status
      completed_pages
    }
    schedule {
      id
      updated_at
      scope
      target_id
      schedulable {
        ... on Form {
          ...FormDetails
        }
      }
    }
    set_status
    step
  }
}
    ${FormDetailsFragmentDoc}`;
export type GetEnrollmentStepsQueryResult = ApolloReactCommon.QueryResult<GetEnrollmentStepsQuery, GetEnrollmentStepsQueryVariables>;
export const GetEnrollmentStepsStatusDocument = gql`
    query GetEnrollmentStepsStatus {
  getEnrollmentSteps {
    set_status
    step
  }
}
    `;
export type GetEnrollmentStepsStatusQueryResult = ApolloReactCommon.QueryResult<GetEnrollmentStepsStatusQuery, GetEnrollmentStepsStatusQueryVariables>;
export const GetEnrollmentStepsConfigDocument = gql`
    query GetEnrollmentStepsConfig {
  getEnrollmentSteps(alsoDisabled: true) {
    active
    set_status
    step
  }
}
    `;
export type GetEnrollmentStepsConfigQueryResult = ApolloReactCommon.QueryResult<GetEnrollmentStepsConfigQuery, GetEnrollmentStepsConfigQueryVariables>;
export const GetEventsDocument = gql`
    query GetEvents($filter: FindEventFilterInput, $order: OrderEventsInput, $limit: Int, $skip: Int) {
  getEvents(filter: $filter, order: $order, limit: $limit, skip: $skip) {
    items {
      ...EventDetails
    }
    paginationInfo {
      hasMore
      total
    }
  }
}
    ${EventDetailsFragmentDoc}`;
export type GetEventsQueryResult = ApolloReactCommon.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const GetEventDocument = gql`
    query GetEvent($id: UID!, $includeSchedule: Boolean = false, $includeQuestionnaire: Boolean = false, $includeDiary: Boolean = false, $includePatient: Boolean = false) {
  getEvent(id: $id) {
    id
    completed_at
    completed_pages
    persisted
    schedule_id
    patient_id
    form_ids
    questionnaire @include(if: $includeQuestionnaire) {
      ...FormDetails
    }
    schedule @include(if: $includeSchedule) {
      ...ScheduleDetails
    }
    diary @include(if: $includeDiary) {
      ...DiaryDetails
    }
    patient @include(if: $includePatient) {
      ...PatientDetails
    }
    status
    updated_at
  }
}
    ${FormDetailsFragmentDoc}
${ScheduleDetailsFragmentDoc}
${DiaryDetailsFragmentDoc}
${PatientDetailsFragmentDoc}`;
export type GetEventQueryResult = ApolloReactCommon.QueryResult<GetEventQuery, GetEventQueryVariables>;
export const GetFormsTitleDocument = gql`
    query GetFormsTitle($filter: FindFormFilterInput) {
  getForms(filter: $filter) {
    id
    title
  }
}
    `;
export type GetFormsTitleQueryResult = ApolloReactCommon.QueryResult<GetFormsTitleQuery, GetFormsTitleQueryVariables>;
export const GetFormsListDocument = gql`
    query GetFormsList($filter: FindFormFilterInput, $order: OrderFormInput) {
  getForms(filter: $filter, order: $order) {
    id
    title
    draft
    type
    updated_at
  }
}
    `;
export type GetFormsListQueryResult = ApolloReactCommon.QueryResult<GetFormsListQuery, GetFormsListQueryVariables>;
export const GetFormDocument = gql`
    query GetForm($id: UID!, $pageId: UID, $fetchAnswer: Boolean! = false, $patientId: UID, $eventId: UID) {
  getForm(id: $id) {
    ...FormDetails
    page(id: $pageId) {
      ...PageDetails
    }
  }
}
    ${FormDetailsFragmentDoc}
${PageDetailsFragmentDoc}`;
export type GetFormQueryResult = ApolloReactCommon.QueryResult<GetFormQuery, GetFormQueryVariables>;
export const GetAvailableTrialsDocument = gql`
    query GetAvailableTrials {
  getAvailableTrials {
    id
    description
    status
    info
    role
    scopes
  }
}
    `;
export type GetAvailableTrialsQueryResult = ApolloReactCommon.QueryResult<GetAvailableTrialsQuery, GetAvailableTrialsQueryVariables>;
export const ExchangeTokenDocument = gql`
    query ExchangeToken($uuid: UID!) {
  exchangeToken(id: $uuid)
}
    `;
export type ExchangeTokenQueryResult = ApolloReactCommon.QueryResult<ExchangeTokenQuery, ExchangeTokenQueryVariables>;
export const GetMonitorProgressDocument = gql`
    query GetMonitorProgress {
  getMonitorProgress {
    events {
      total
      type
    }
    queries {
      total
      type
    }
    totalEvents
    totalPatients
    totalQueries
  }
}
    `;
export type GetMonitorProgressQueryResult = ApolloReactCommon.QueryResult<GetMonitorProgressQuery, GetMonitorProgressQueryVariables>;
export const CurrentOperatorDocument = gql`
    query CurrentOperator {
  currentOperator {
    ...OperatorDetails
    cro {
      ...CroDetails
    }
    site {
      ...SiteDetails
    }
  }
}
    ${OperatorDetailsFragmentDoc}
${CroDetailsFragmentDoc}
${SiteDetailsFragmentDoc}`;
export type CurrentOperatorQueryResult = ApolloReactCommon.QueryResult<CurrentOperatorQuery, CurrentOperatorQueryVariables>;
export const GetDiariesDocument = gql`
    query GetDiaries {
  getDiaries {
    ...DiaryDetails
  }
}
    ${DiaryDetailsFragmentDoc}`;
export type GetDiariesQueryResult = ApolloReactCommon.QueryResult<GetDiariesQuery, GetDiariesQueryVariables>;
export const GetPatientsDocument = gql`
    query GetPatients($site_id: UID, $cro_id: UID, $limit: Int!, $skip: Int!, $search: String, $order: OrderPatientsInput) {
  getPatients(order: $order, filter: {_or: [{code: {like: $search}}, {yob: {like: $search}}, {gender: {like: $search}}, {status: {like: $search}}], site_id: {eq: $site_id}, cro_id: {eq: $cro_id}}, limit: $limit, skip: $skip) {
    items {
      ...PatientDetails
    }
    paginationInfo {
      hasMore
      total
    }
  }
  getEnrollmentSteps {
    set_status
    step
  }
}
    ${PatientDetailsFragmentDoc}`;
export type GetPatientsQueryResult = ApolloReactCommon.QueryResult<GetPatientsQuery, GetPatientsQueryVariables>;
export const GetPatientDocument = gql`
    query GetPatient($patient_uuid: UID!, $date_start: DateTime, $date_end: DateTime, $includeDiaries: Boolean = false, $includeQuestionnaires: Boolean = false, $includeEvents: Boolean = false, $includeCommonForms: Boolean = false, $includeEnrollment: Boolean = false) {
  getPatient(id: $patient_uuid) {
    ...PatientDetails
    enrollment @include(if: $includeEnrollment) {
      ...EventPreview
      schedule {
        ...SchedulePreview
      }
    }
    common_forms @include(if: $includeCommonForms) {
      ...EventPreview
      schedule_id
      questionnaire {
        id
        title
      }
      schedule {
        ...SchedulePreview
      }
    }
    diaries @include(if: $includeDiaries) {
      ...EventPreview
      diary {
        id
        title
        form_id
      }
    }
    questionnaires @include(if: $includeQuestionnaires) {
      ...EventPreview
      questionnaire {
        id
        title
      }
    }
    events(date_start: $date_start, date_end: $date_end) @include(if: $includeEvents) {
      ...EventPreview
      schedule {
        ...SchedulePreview
      }
    }
  }
}
    ${PatientDetailsFragmentDoc}
${EventPreviewFragmentDoc}
${SchedulePreviewFragmentDoc}`;
export type GetPatientQueryResult = ApolloReactCommon.QueryResult<GetPatientQuery, GetPatientQueryVariables>;
export const GetPatientPreviewDocument = gql`
    query GetPatientPreview($patient_uuid: UID!) {
  getPatient(id: $patient_uuid) {
    id
    common_forms {
      ...EventPreview
      questionnaire {
        id
        title
      }
      schedule {
        ...SchedulePreview
      }
    }
    enrollment {
      ...EventPreview
      schedule {
        ...SchedulePreview
      }
    }
    events {
      ...EventPreview
      child_events {
        ...EventPreview
        schedule {
          ...SchedulePreview
        }
      }
      schedule {
        ...SchedulePreview
      }
    }
  }
}
    ${EventPreviewFragmentDoc}
${SchedulePreviewFragmentDoc}`;
export type GetPatientPreviewQueryResult = ApolloReactCommon.QueryResult<GetPatientPreviewQuery, GetPatientPreviewQueryVariables>;
export const GetQueriesCountDocument = gql`
    query GetQueriesCount($filter: FindQueriesFiltersInput) {
  getQueries(filter: $filter, skip: 0, limit: 1) {
    paginationInfo {
      total
    }
  }
}
    `;
export type GetQueriesCountQueryResult = ApolloReactCommon.QueryResult<GetQueriesCountQuery, GetQueriesCountQueryVariables>;
export const GetQueriesDocument = gql`
    query GetQueries($filter: FindQueriesFiltersInput, $order: OrderQueriesInput, $limit: Int, $skip: Int) {
  getQueries(filter: $filter, order: $order, skip: $skip, limit: $limit) {
    items {
      ...QueryDetails
    }
    paginationInfo {
      hasMore
      total
    }
  }
}
    ${QueryDetailsFragmentDoc}`;
export type GetQueriesQueryResult = ApolloReactCommon.QueryResult<GetQueriesQuery, GetQueriesQueryVariables>;
export const GetQueryDocument = gql`
    query GetQuery($id: UID!) {
  getQuery(id: $id) {
    ...QueryDetails
  }
}
    ${QueryDetailsFragmentDoc}`;
export type GetQueryQueryResult = ApolloReactCommon.QueryResult<GetQueryQuery, GetQueryQueryVariables>;
export const GetSchedulesDocument = gql`
    query GetSchedules($filter: FindSchedulesFiltersInput, $order: OrderSchedulesInput) {
  getSchedules(filter: $filter, order: $order) {
    ...ScheduleDetails
  }
}
    ${ScheduleDetailsFragmentDoc}`;
export type GetSchedulesQueryResult = ApolloReactCommon.QueryResult<GetSchedulesQuery, GetSchedulesQueryVariables>;
export const GetScheduleDocument = gql`
    query GetSchedule($id: UID!) {
  getSchedule(id: $id) {
    ...ScheduleDetails
  }
}
    ${ScheduleDetailsFragmentDoc}`;
export type GetScheduleQueryResult = ApolloReactCommon.QueryResult<GetScheduleQuery, GetScheduleQueryVariables>;
export const GetSiteDocument = gql`
    query GetSite($id: UID!) {
  getSite(id: $id) {
    ...SiteDetails
    primaryInvestigators {
      ...OperatorDetails
    }
    operators {
      ...OperatorDetails
    }
  }
}
    ${SiteDetailsFragmentDoc}
${OperatorDetailsFragmentDoc}`;
export type GetSiteQueryResult = ApolloReactCommon.QueryResult<GetSiteQuery, GetSiteQueryVariables>;
export const ConfigurationStatusDocument = gql`
    query ConfigurationStatus {
  configurationStatus {
    completed
    current
    hint
    status
    total
  }
}
    `;
export type ConfigurationStatusQueryResult = ApolloReactCommon.QueryResult<ConfigurationStatusQuery, ConfigurationStatusQueryVariables>;
export const GetConfigurationValuesDocument = gql`
    query GetConfigurationValues($fieldnames: [ConfigurationName!]) {
  getConfigurationValues(fieldnames: $fieldnames) {
    fieldname
    fieldvalue
    fieldtype
  }
}
    `;
export type GetConfigurationValuesQueryResult = ApolloReactCommon.QueryResult<GetConfigurationValuesQuery, GetConfigurationValuesQueryVariables>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "Schedulable",
        "possibleTypes": [
          {
            "name": "Form"
          }
        ]
      }
    ]
  }
};
      export default result;
    